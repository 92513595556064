import { Button, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { propFileField, propNumberField, propTextField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { apiUrl } from "../../common/api";
import { FundDto } from "../project/common";
import Image from "mui-image";
import { propNotRegex } from "../../common/entityStoreValidators";
import { UseListStore } from "../../common/listStore";

export function FundDialog(props: { close: () => void; fund?: FundDto; useFundsStore: UseListStore<FundDto>; projectId: string; }) {
    const [useStore] = useState(() => createEntityStore<Partial<FundDto>>({
        data: props.fund ?? {
            projectId: props.projectId,
            name: "",
            url: "",
        },
        path: "main/admin/funds",
        id: props.fund?.id ?? null,
        fetchOnInit: false,
        onSaved() {
            props.useFundsStore.getState().fetch(false, true);
            props.close();
        },
        propValidators: [
            propNotRegex("url", /^[a-z0-9/-]+$/, "URL can contain only lowercase letters, numbers, / and -"),
        ],
    }));
    const { cardImageResource, cardImageResourceId } = useStore(p => ({
        cardImageResource: p.clientData.cardImageResource,
        cardImageResourceId: p.serverData.cardImageResourceId,
    }));
    return <>
        <DialogTitle>Fund</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={4}>{propTextField(useStore)("name", "Name")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("url", "URL path")}</Grid>
                <Grid item xs={4}>{propNumberField(useStore)("order", "orderFormatted", "Order")}</Grid>
                <Grid item xs={4}>{propFileField(useStore)("cardImageResource", "Card image")}</Grid>
                <Grid item xs={4}><Paper sx={{ width: "fit-content" }}><Image width={250} height={130} fit="cover"
                    src={cardImageResource != null ? `data:image/*;base64,${cardImageResource}` :
                        cardImageResourceId != null ? `${apiUrl}main/admin/resources/${cardImageResourceId}/content` :
                            ""}
                /></Paper></Grid>
                <Grid item xs={12}>{propTextField(useStore)("pageText", "Page text", { multiline: true })}</Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
