import { Clear, Search } from "@mui/icons-material";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { EnumItemDto } from "./enum";
import { ArrayElement } from "./utils";
import { useAccountStore } from "./account";
import { DateField } from "./form";
import { enumAllValue, getListPropFilterName, getListPropFilterProps, ListProp, ListStore, UseListStore } from "./listStore";
import { StoreWrapper } from "./store";
import { Column, DataGrid } from "./DataGrid";

export interface ListStoreGridConfig<T> {
    header?(): JSX.Element;
    columnValueTransforms?: { [U in keyof T]?: (value: T[U] | undefined) => T[U] | undefined; };
    onDoubleClick?: (item: T) => void;
    autoHeight?: boolean;
    style?: React.CSSProperties;
    rowsPerPageOptions?: number[];
    readOnly?: boolean;
    onRowSelected?(id: any): void;
    instantSearch?: true;
    externalFilters?: () => JSX.Element;
    clearExternalFilters?: () => void;
    onClear?(): void;
}

export function ListStoreGrid<T>(props: { useStore: UseListStore<T>; config?: ListStoreGridConfig<T>; }) {
    const store = props.useStore();
    const listProps = getListProps(store);

    function getColumns(): Column<T>[] {
        return listProps.filter(p => p.prop.column != null)
            .map(p => ({
                prop: p.prop.column!,
                width: p.prop.width,
                flex: p.prop.flex,
                label: p.prop.label,
                type: p.prop.type,
                checkedValue: p.prop.checkedValue,
                setValue: p.prop.setValue,
                getValue: p.enumItems ? (r: string | number) => p.enumItems!.find(q => q.id === r)?.name : undefined,
            }));
    }

    return <>
        <DataGrid
            rows={store.filteredSortedItems}
            columns={getColumns()}
            getId={store.config.getId}
            onRowClick={store.setSelectedItem}
            onRowDoubleClick={props.config?.onDoubleClick}
            selectedRow={store.selectedItem}
        />
    </>
}

export function renderListStore<T>(useStore: UseListStore<T>, config: ListStoreGridConfig<T> = {}) {
    return <StoreWrapper useStore={() => useStore(p => ({ listPropsId: p.listPropsId }))} render={store => <>
        {!config.readOnly && <ListStoreFilters key={"filters" + store.listPropsId} useStore={useStore} config={config} />}
        {config.header && config.header()}
        <ListStoreGrid key={"grid" + store.listPropsId} useStore={useStore} config={config} />
    </>} />
}

export function listHeaderItems(items: (null | false | [string, () => JSX.Element])[], itemsRight: (null | false | [string, () => JSX.Element])[] = []) {
    const definedItems = (items as [string, () => JSX.Element][]).filter(p => p);
    const definedItemsRight = (itemsRight as [string, () => JSX.Element][]).filter(p => p);
    return definedItems.length === 0 && definedItemsRight.length === 0 ? undefined : () => <Grid container flexWrap="nowrap" pb={1}>
        <Grid item container xs spacing={1} sx={{ flexGrow: 1 }}>{definedItems.map(p => <Grid item key={p[0]}>{p[1]()}</Grid>)}</Grid>
        <Grid item container xs="auto" spacing={1} flexWrap="nowrap">{definedItemsRight.map(p => <Grid item key={p[0]}>{p[1]()}</Grid>)}</Grid>
    </Grid>;
}

function getListProps<T>(store: Pick<ListStore<T>, "listProps">) {
    const accountStore = useAccountStore();
    return (store.listProps || []).map(p => {
        const enumStore = p.enumStore ? p.enumStore(p => ({ items: p.items, fetching: p.fetching })) : null;
        const fetching = enumStore ? enumStore.fetching : false;
        const readOnlyValue = p.readOnlyValue ? p.readOnlyValue(accountStore) : null;
        let enumItems = enumStore ? enumStore.items : null;
        if (enumItems && readOnlyValue != null && !enumItems.some(p => p.id === readOnlyValue)) {
            enumItems = enumItems.concat({ id: readOnlyValue as string, code: readOnlyValue, name: "" } as EnumItemDto);
        }
        return { prop: p, enumItems, fetching, readOnlyValue };
    });
}

export function ListStoreFilters<T>(props: { useStore: UseListStore<T>; config: ListStoreGridConfig<T>; }) {
    const store = props.useStore(p => ({
        dataFilters: p.dataFilters,
        listProps: p.listProps,
        fetch: p.fetch,
        setFilterData: p.setFilterData,
        resetFilterData: p.resetFilterData,
    }));
    const listProps = getListProps(store);

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            store.fetch(undefined, true);
        }
    };

    function getListPropFilterValue(item: ListProp<T>, getDefault: () => string | number | null) {
        const filter = store.dataFilters[getListPropFilterName(item)];
        return filter ? filter.value : getDefault();
    }

    function getListPropFilterValueFormatted(item: ListProp<T>) {
        const filter = store.dataFilters[getListPropFilterName(item)];
        return filter ? filter.valueFormatted : undefined;
    }

    function setPropFilterValue(
        item: ListProp<T>,
        value: string | number | boolean | null | (string | number | boolean | null)[],
        valueFormatted?: string | null | (string | null | undefined)[]) {
        const filterName = getListPropFilterName(item);
        const filter = store.dataFilters[filterName];
        if (value != null || valueFormatted != null) {
            store.setFilterData(filterName, {
                props: getListPropFilterProps(item),
                value,
                valueFormatted,
                operator: item.operator || (item.enumStore ? "Equal" : "Like")
            });
        } else if (filter != null) {
            store.setFilterData(filterName, null);
        }
        if (props.config.instantSearch) {
            store.fetch(undefined, true);
        }
    }

    function getArrayFilterValue<T>(index: number, filterValue: T | T[]) {
        if (Array.isArray(filterValue)) {
            return filterValue[index];
        } else {
            return null;
        }
    }

    function setArrayFilterValue<T>(index: number, filterValue: T | T[], value: T) {
        const newFilterValue = Array.isArray(filterValue) ? [...filterValue] : [];
        newFilterValue[index] = value;
        if (newFilterValue.some(p => p != null)) {
            return newFilterValue;
        } else {
            return null;
        }
    }

    function renderFilter(item: ArrayElement<typeof listProps>) {
        const width = item.prop.filterWidth || 1;
        if (item.prop.type === "date" && item.prop.operator === "Between") {
            const renderRangePart = (label: string, index: number) => {
                return <Grid item xs={6 * width} sm={3 * width} lg={1 * width} key={getListPropFilterProps(item.prop)[0] as string + index}>
                    <DateField
                        disabled={item.readOnlyValue != null}
                        label={item.prop.label + " " + label}
                        setValue={(date, dateFormatted) => setPropFilterValue(item.prop,
                            setArrayFilterValue(index, getListPropFilterValue(item.prop, () => null), date),
                            setArrayFilterValue(index, getListPropFilterValueFormatted(item.prop), dateFormatted))}
                        value={item.readOnlyValue != null ?
                            item.readOnlyValue as string :
                            getArrayFilterValue(index, getListPropFilterValue(item.prop, () => "")) as string}
                        valueFormatted={getArrayFilterValue(index, getListPropFilterValueFormatted(item.prop))}
                        onKeyDown={onKeyDown}
                    />
                </Grid>;
            }
            return [renderRangePart("from", 0), renderRangePart("to", 1)];
        } else if (item.enumItems) {
            return <Grid item xs={6 * width} sm={3 * width} lg={1 * width} key={getListPropFilterProps(item.prop)[0] as string}>
                <FormControl disabled={item.readOnlyValue != null}>
                    <InputLabel>{item.prop.label}</InputLabel>
                    <Select
                        label={item.prop.label}
                        value={item.readOnlyValue != null ? item.readOnlyValue : getListPropFilterValue(item.prop, () => enumAllValue)}
                        onChange={e => setPropFilterValue(item.prop, (e.target.value == null || e.target.value === enumAllValue) ? null : e.target.value)}
                    >
                        <MenuItem value={enumAllValue}>All</MenuItem>
                        {item.enumItems.map(p =>
                            <MenuItem value={p.id} key={p.id}>{p.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>;
        } else {
            return <Grid item xs={6 * width} sm={3 * width} lg={1 * width} key={getListPropFilterProps(item.prop)[0] as string}>
                <TextField
                    disabled={item.readOnlyValue != null}
                    label={item.prop.label}
                    onChange={(event) => setPropFilterValue(item.prop, event.currentTarget.value || null)}
                    value={item.readOnlyValue != null ? item.readOnlyValue : getListPropFilterValue(item.prop, () => "")}
                    onKeyDown={onKeyDown}
                />
            </Grid>;
        }
    }

    function clear() {
        store.resetFilterData();
        if (props.config.clearExternalFilters) {
            props.config.clearExternalFilters();
        }
        if (props.config.instantSearch) {
            store.fetch(undefined, true);
        }
        if (props.config.onClear) {
            props.config.onClear();
        }
    }

    const filters = listProps.filter(p => p.prop.filter != null).map(p => renderFilter(p));

    return <>
        {(filters.length > 0 || props.config.externalFilters != null) && <Grid container>
            <Grid item xs={12} container columnSpacing={2}>
                {props.config.externalFilters && props.config.externalFilters()}
                {filters}
                <Grid container item xs={6} sm={3} lg={1} spacing={2} wrap="nowrap" mb={2}>
                    {!props.config.instantSearch && <Grid item xs="auto"><Button title="Search" variant="contained" onClick={() => store.fetch(undefined, true)}><Search /></Button></Grid>}
                    <Grid item xs="auto"><Button title="Clear" variant="contained" onClick={clear}><Clear /></Button></Grid>
                </Grid>
            </Grid>
        </Grid>}
    </>;
}

export const ListHeadButtonStyle: React.CSSProperties = { paddingLeft: 10, paddingRight: 10, minWidth: 0 };
