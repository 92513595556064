import { Navigate, useLocation } from "react-router-dom";
import { useAccountStore } from "../common/account";

export function RequireAuth({ children }: { children: React.ReactNode }) {
    let auth = useAccountStore();
    let location = useLocation();

    if (!auth.accessToken) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <>{children}</>;
}
