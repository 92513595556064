import { Navigate, useLocation } from "react-router-dom";
import { useAccountStore } from "../common/account";

export function RequireAnonymous({ children }: { children: React.ReactNode }) {
    let auth = useAccountStore();
    const location = useLocation();

    if (auth.accessToken) {
        return <Navigate to={location.state?.from || "/"} replace />;
    }

    return <>{children}</>;
}
