import { useEffect } from "react";
import { Observable } from "rxjs/internal/Observable";
import { Subscription } from "rxjs/internal/Subscription";
import { createAxios } from "./axios";
import { EnumItemDto } from "./enum";
import { shallow } from "zustand/shallow";
import { ifNull } from "./utils";
import { AccountStore, useAccountStore } from "./account";
import { BehaviorSubject, tap } from "rxjs";
import { orderBy } from "lodash";
import { create } from "zustand";

export const enumAllValue = 0;

export interface ListDto<T> {
    count?: number;
    items: T[];
}

export interface FilterDto {
    items: FilterItemDto[];
}

export interface FilterItemDto {
    properties: string[];
    value: any;
    operator: keyof typeof FilterItemOperator;
}

export enum FilterItemOperator {
    Like,
    Equal,
    GreaterThanOrEqual,
    GreaterThan,
    LessThanOrEqual,
    LessThan,
    In,
    Between,
}

export interface PageDto {
    index: number;
    size: number;
}

export enum SortDirection {
    Ascending,
    Descending
}

export interface SortDto {
    property: string;
    direction: keyof typeof SortDirection;
}

export interface FilterSortPageDto {
    filter?: FilterDto;
    sort?: SortDto;
    page?: PageDto;
    count?: boolean;
}

export interface DataFilter<T> {
    props: (keyof T)[];
    value: string | number | boolean | null | (string | number | boolean | null)[];
    valueFormatted?: string | null | (string | null | undefined)[];
    operator: keyof typeof FilterItemOperator;
}

export interface DataFilters<T> {
    [filter: string]: DataFilter<T>;
};

export declare type GridSortDirection = 'asc' | 'desc' | null | undefined;
export interface GridSortItem { field: string; sort: GridSortDirection; }
export declare type GridSortModel = GridSortItem[];


export interface ListStore<T> {
    config: ListStoreConfig<T>;
    path: string | undefined;
    items: T[];
    filteredSortedItems: T[];
    selectedItem: T | null;
    count: number;
    fetching: boolean;
    abortController: AbortController | null;
    initialized: boolean;
    dataFilters: DataFilters<T>;
    fetchDataFilters: DataFilters<T>;
    externalDataFilters: DataFilter<T>[];
    externalFetchDataFilters: DataFilter<T>[];
    sortModel: GridSortModel;
    dataSubscription: Subscription | null,
    initializedCount: number,
    queryFilter: FilterDto | null,
    items$: Observable<{ items: T[]; isFetching: boolean; }>;
    selectedItem$: BehaviorSubject<T | null>;
    listProps: ListProp<T>[];
    listPropsId: number;
    fetch(updateFetchFilterData?: boolean, forceFetch?: boolean): void;
    init(): void;
    dispose(): void;
    setFilterData(name: string, data: DataFilter<T> | null): void;
    resetFilterData(): void;
    setSortModel(model: GridSortModel): void;
    setSelectedItem(selectedItem: T | null): void;
    setPath(path: string | undefined): void;
    setItems(items: T[], isFetching: boolean): void;
    setListProps(listProps: ListProp<T>[]): void;
    setExternalDataFilters(filters: DataFilter<T>[]): void;
}

export interface ListProp<T> {
    label: string;
    column?: keyof T & string;
    width?: number;
    filter?: (keyof T)[] | true;
    filterWidth?: number;
    operator?: keyof typeof FilterItemOperator;
    sortable?: boolean;
    enumStore?: UseListStore<EnumItemDto>;
    type?: "currency" | "date" | "datetime" | "bool" | "number" | "radio";
    checkedValue?: string | number | boolean | null;
    setValue?(rowId: string | number, value: string | number | boolean | null | undefined): void;
    align?: "right";
    flex?: number;
    readOnlyValue?: (accountStore: AccountStore) => string | number | (string | number)[] | null;
}

export interface ListStoreConfig<T> {
    listProps?: (ListProp<T> | false | null | undefined)[];
    autoInitialFetch?: boolean;
    addIndexId?: boolean;
    getId(item: T): string | number;
    supportsApiFilterSortPage?: true;
    path?: string;
    qs?: { [name: string]: string | number | boolean | null | undefined; },
    data?: Observable<{ data: T[]; isFetching: boolean; }>;
    autoSelectFirstItem?: true;
}

function addIdIndex<T>(items: T[]) {
    return items.map((p, i) => ({ id: i, ...p }));
}

export function addIndex<T>(items: T[]) {
    return items.map((p, i) => ({ index: i, ...p }));
}

export function getListPropFilterProps<T>(prop: ListProp<T>) {
    return prop.filter == null ? [] : prop.filter === true ? [prop.column!] : prop.filter;
}

export function getListPropFilterName<T>(item: ListProp<T>): string {
    return item.filter === true ? item.column as string : item.filter!.join(";");
}

function applyFilters<T>(items: T[], store: ListStore<T>) {
    for (const filter of Object.values(store.fetchDataFilters).concat(store.externalFetchDataFilters)) {
        if (filter.operator === "In") {
            const value = filter.value as (string | number | boolean)[];
            items = items.filter(p => filter.props.some(r => value.indexOf(p[r] as string | number | boolean) >= 0));
        } else if (filter.operator === "Like") {
            const value = filter.value as string;
            const regex = new RegExp(escapeRegExp(value).replace(/%/g, ".*").normalize("NFD").replace(/[\u0300-\u036f]/g, ""), "i");
            items = items.filter(p => filter.props.some(r => regex.test((p[r] as string).normalize("NFD").replace(/[\u0300-\u036f]/g, ""))));
        } else if (filter.operator === "Equal") {
            const value = filter.value;
            items = items.filter(p => filter.props.some(r => p[r] === value));
        } else if (filter.operator === "Between") {
            const value = filter.value as (string | number | null)[] || [];
            const from = value[0];
            const to = value[1];
            items = items.filter(p => filter.props.some(r => (from == null || p[r] >= from) && (to == null || p[r] <= to)));
        } else if (filter.operator === "GreaterThan") {
            const value = filter.value as string | number;
            items = items.filter(p => filter.props.some(r => p[r] > value));
        } else if (filter.operator === "LessThan") {
            const value = filter.value as string | number;
            items = items.filter(p => filter.props.some(r => p[r] < value));
        } else if (filter.operator === "GreaterThanOrEqual") {
            const value = filter.value as string | number;
            items = items.filter(p => filter.props.some(r => p[r] >= value));
        } else if (filter.operator === "LessThanOrEqual") {
            const value = filter.value as string | number;
            items = items.filter(p => filter.props.some(r => p[r] <= value));
        }
    }
    return items;
}

function escapeRegExp(text: string) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function applySort<T>(items: T[], model: GridSortModel) {
    items = orderBy(items, model.map(p => p.field), model.map(p => p.sort || "asc"));
    return items;
}

export function getFilter<T>(store: ListStore<T>): FilterDto {
    function getFinalFilterValue(filter: DataFilter<T>) {
        return filter == null ? null : filter.operator === "Like" ? filter.value + "%" : filter.value;
    }
    let filters = store.fetchDataFilters;
    const accountStore = useAccountStore.getState();
    for (const p of store.listProps || []) {
        if (p.readOnlyValue) {
            const v = p.readOnlyValue(accountStore);
            if (v != null) {
                filters = { ...filters }
                filters[getListPropFilterName(p)] = {
                    props: getListPropFilterProps(p),
                    operator: "Equal",
                    value: v,
                };
            }
        }
    }
    return {
        items: Object.values(filters).concat(store.externalFetchDataFilters)
            .map(p => ({
                properties: p.props as string[],
                value: getFinalFilterValue(p),
                operator: p.operator,
            }))
    };
}

export function getSort<T>(store: ListStore<T>): SortDto | undefined {
    return store.sortModel.length !== 1 ? undefined : {
        property: store.sortModel[0].field,
        direction: store.sortModel[0].sort === "asc" ? "Ascending" : "Descending",
    }
}

export function createListStore<T>(config: ListStoreConfig<T> = { getId: p => (p as any).id }): UseListStore<T> {
    const itemsSubject = new BehaviorSubject<{ items: T[]; isFetching: boolean; }>({
        items: [],
        isFetching: false,
    });
    const useStore = create<ListStore<T>>((set, get) => ({
        config,
        path: config.path,
        items: [],
        filteredSortedItems: [],
        selectedItem: null,
        count: 0,
        fetching: false,
        abortController: null,
        initialized: false,
        dataFilters: {},
        fetchDataFilters: {},
        externalDataFilters: [],
        externalFetchDataFilters: [],
        sortModel: [],
        dataSubscription: null,
        initializedCount: 0,
        queryFilter: null,
        items$: itemsSubject.pipe(tap({
            subscribe() {
                get().init();
            },
            unsubscribe() {
                get().dispose();
            },
        })),
        selectedItem$: new BehaviorSubject<T | null>(null),
        listProps: (config.listProps || []).filter(p => p) as ListProp<T>[],
        listPropsId: 1,
        fetch(updateFetchFilterData = false, forceFetch = false) {
            if (updateFetchFilterData) {
                set({ fetchDataFilters: get().dataFilters, externalFetchDataFilters: get().externalDataFilters });
            }
            if (config.data) {
                const filteredItems = applyFilters(get().items, get());
                const filteredSortedItems = applySort(filteredItems, get().sortModel);
                set({ filteredSortedItems, count: filteredSortedItems.length });
                refreshSelectedItem(get());
                autoSelectFirstItem(get());
                return;
            }
            const currentAbortController = get().abortController;
            if (currentAbortController) {
                currentAbortController.abort();
            }
            const abortController = new AbortController();
            set({ fetching: true, abortController });
            itemsSubject.next({ items: get().items, isFetching: get().fetching });
            const path = get().path;
            (async () => {
                try {
                    if (config.supportsApiFilterSortPage) {
                        if (path != null) {
                            const filter = getFilter(get());
                            const prevQueryFilter = get().queryFilter;
                            const query: FilterSortPageDto = {
                                filter,
                                sort: getSort(get()),
                                count: !prevQueryFilter ? true : JSON.stringify(prevQueryFilter) !== JSON.stringify(filter),
                            };
                            const { data } = await createAxios().post<ListDto<T>>(path!, query, {
                                signal: abortController.signal,
                                params: config.qs,
                            });
                            const items = config.addIndexId ? addIdIndex(data.items) : data.items;
                            set(state => ({
                                items, filteredSortedItems: items,
                                count: data.count == null ? state.count : data.count, queryFilter: filter, initialized: true
                            }));
                            itemsSubject.next({ items: get().items, isFetching: get().fetching });
                        }
                    } else {
                        if (!get().initialized || forceFetch) {
                            if (path != null) {
                                const { data } = await createAxios().get<T[]>(path, {
                                    signal: abortController.signal,
                                    params: config.qs,
                                });
                                const items = config.addIndexId ? addIdIndex(data) : data;
                                const filteredItems = applyFilters(items, get());
                                const filteredSortedItems = applySort(filteredItems, get().sortModel);
                                set({ items, filteredSortedItems, count: filteredSortedItems.length, initialized: true });
                            }
                        } else {
                            const filteredItems = applyFilters(get().items, get());
                            const filteredSortedItems = applySort(filteredItems, get().sortModel);
                            set({ filteredSortedItems, count: filteredSortedItems.length });
                        }
                    }
                } finally {
                    if (abortController.signal.aborted) {
                        return;
                    }
                    set({ fetching: false, abortController: null });
                    itemsSubject.next({ items: get().items, isFetching: get().fetching });
                    refreshSelectedItem(get());
                    autoSelectFirstItem(get());
                }
            })();
        },
        init() {
            set(p => ({ initializedCount: p.initializedCount + 1 }));
            if (get().initializedCount > 1) {
                return;
            }
            if (config.autoInitialFetch !== false && !get().fetching) {
                get().fetch();
            }
            if (config.data && get().dataSubscription == null) {
                const dataSubscription = config.data.subscribe(p => {
                    get().setItems(p.data, p.isFetching);
                });
                set({ dataSubscription, initialized: true });
            }
        },
        dispose() {
            set(p => ({ initializedCount: p.initializedCount - 1 }));
            if (get().dataSubscription && get().initializedCount === 0) {
                get().dataSubscription?.unsubscribe();
                set({ dataSubscription: null });
            }
        },
        setFilterData(name, data) {
            const filters = { ...get().dataFilters };
            if (data == null) {
                delete filters[name];
            } else {
                filters[name] = data;
            }
            set({ dataFilters: filters });
        },
        resetFilterData() {
            set({ dataFilters: {} });
        },
        setSortModel(sortModel) {
            set({ sortModel });
            get().fetch();
        },
        setSelectedItem(selectedItem: T | null) {
            set({ selectedItem });
            get().selectedItem$.next(selectedItem);
        },
        setPath(path) {
            set({ path });
        },
        setItems(items, isFetching) {
            items = config.addIndexId ? addIdIndex(items) : items;
            const filteredItems = applyFilters(items, get());
            const filteredSortedItems = applySort(filteredItems, get().sortModel);
            set({ items, filteredSortedItems, count: filteredSortedItems.length, fetching: isFetching });
            itemsSubject.next({ items: get().items, isFetching: get().fetching });
            refreshSelectedItem(get());
            autoSelectFirstItem(get());
        },
        setListProps(listProps) {
            set(p => ({ listProps, listPropsId: p.listPropsId + 1 }));
        },
        setExternalDataFilters(filters) {
            set({ externalDataFilters: filters });
        },
    }));
    return Object.assign(function <U = Omit<ListStore<T>, "init" | "dispose">>(selector?: (store: ListStore<T>) => U, compare: (a: U, b: U) => boolean = shallow) {
        const store = selector ?
            useStore(p => ({ init: p.init, dispose: p.dispose, selected: selector(p) }), (a, b) => compare(a.selected, b.selected)) :
            useStore(p => ({ init: p.init, dispose: p.dispose, selected: p as U }), (a, b) => compare(a.selected, b.selected));
        return initStore(store).selected;
    }, { getState: useStore.getState });
}

export interface UseListStore<T> {
    (): ListStore<T>;
    <U = Omit<ListStore<T>, "init" | "dispose">>(selector?: (store: ListStore<T>) => U, compare?: (a: U, b: U) => boolean): U;
    getState(): ListStore<T>;
}

function initStore<T extends { init: () => void; dispose: () => void; }>(store: T) {
    const { init, dispose } = store;
    useEffect(() => {
        init();
        return () => dispose();
    }, [init, dispose]);
    return store;
}

export function getId<T>(p: T, store: ListStore<T>) {
    return store.config.getId ? store.config.getId!(p) : (p as { id: string | number; }).id;
}

function refreshSelectedItem<T>(store: ListStore<T>) {
    if (store.selectedItem != null) {
        const selectedId = getId(store.selectedItem!, store);
        const selectedItem = store.filteredSortedItems.find(p => getId(p, store) === selectedId);
        store.setSelectedItem(ifNull(selectedItem, null));
    }
}

function autoSelectFirstItem<T>(store: ListStore<T>) {
    if (store.config.autoSelectFirstItem && store.selectedItem == null) {
        store.setSelectedItem(store.filteredSortedItems[0]);
    }
}
