import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { propBoolField, propFileField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { ProspectDto } from "./common";
import { UseListStore } from "../../common/listStore";

interface ProspectImportDto {
    file: File;
    projectId: string;
    canAdd: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    canRemoveProspectsFromGroups: boolean;
    canDeleteGroups: boolean;
}

export function ProspectImportDialog(props: {
    close: () => void;
    projectId: string;
    useProspectsStore: UseListStore<ProspectDto>;
}) {
    const [useStore] = useState(() => createEntityStore<ProspectImportDto>({
        data: {
            file: null!,
            projectId: props.projectId,
            canAdd: true,
            canUpdate: true,
            canDelete: false,
            canRemoveProspectsFromGroups: false,
            canDeleteGroups: false,
        },
        path: `main/admin/prospects/import`,
        id: null,
        onSaved() {
            props.useProspectsStore.getState().fetch(false, true);
            props.close();
        },
        propValidators: [
        ],
    }));
    return <>
        <DialogTitle>Prospects import</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>{propFileField(useStore)("file", "Excel file")}</Grid>
                <Grid item xs={6}>{propBoolField(useStore)("canAdd", "Add new prospects")}</Grid>
                <Grid item xs={6}>{propBoolField(useStore)("canUpdate", "Update existing prospects")}</Grid>
                <Grid item xs={6}>{propBoolField(useStore)("canDelete", "Delete prospects not in file")}</Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>{propBoolField(useStore)("canRemoveProspectsFromGroups", "Remove prospects from groups")}</Grid>
                <Grid item xs={6}>{propBoolField(useStore)("canDeleteGroups", "Delete groups not in file")}</Grid>
                <Grid item xs={12}>
                    <Box>First sheet must be named Prospects.</Box>
                    <Box>Following sheets are used for importing groups. Sheet name is used as prospect group name.</Box>
                    <Box>Prospect sheet columns: Title, Surname, Forenames, Email, PreferredName, ProspectId.</Box>
                    <Box>Prospect group sheet columns: ProspectId.</Box>
                    <Box>You can get example file <a href="/prospects import example.xlsx">here</a>.</Box>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
