import { useState } from "react";
import { PageTitle } from "../../layout/PageTitle";
import { createListStore } from "../../common/listStore";
import { listHeaderItems, renderListStore } from "../../common/listStoreUi";
import { Button, Container } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useListItemDeleteApi } from "../../common/entityDelete";
import { useAccountStore } from "../../common/account";
import { ProjectType, useProjectTypesStore } from "../../common/enum";

export function ProjectsPage() {
    const [useProjectsStore] = useState(() => createListStore<ProjectListItemDto>({
        path: "main/admin/projects",
        getId: p => p.id,
        listProps: [
            { column: "type", label: "Type", width: 120, enumStore: useProjectTypesStore },
            { column: "name", label: "Name", flex: 1 },
        ]
    }));
    const navigate = useNavigate();
    const selectedProject = useProjectsStore(p => p.selectedItem);
    const deleteProject = useListItemDeleteApi(useProjectsStore, item => `main/admin/projects/${item.id}`);
    const { isGlobalAdmin } = useAccountStore(p => (({ isGlobalAdmin: p.userInfo?.isGlobalAdmin ?? false })));
    return <Container maxWidth={false}>
        {deleteProject.dialog.render()}
        <PageTitle title="Projects" />
        {renderListStore(useProjectsStore, {
            header: listHeaderItems([
                isGlobalAdmin && ["addGd", () => <Button title="Add" onClick={() => navigate("/projects/gd/new")}><Add /> GD</Button>],
                isGlobalAdmin && ["addTw", () => <Button title="Add" onClick={() => navigate("/projects/tw/new")}><Add /> TW</Button>],
                ["edit", () => <Button disabled={selectedProject == null} title="Edit" onClick={() => navigate(`/projects/${selectedProject!.type === ProjectType.GivingDays ? "gd/" : "tw/"}${selectedProject?.id}`)}><Edit /></Button>],
                isGlobalAdmin && ["remove", () => <Button disabled={selectedProject == null} title="Delete" onClick={() => deleteProject.deleteItem()}><Delete /></Button>],
            ]),
            onDoubleClick: p => navigate(`/projects/${p.type === ProjectType.GivingDays ? "gd/" : "tw/"}${p.id}`),
        })}
    </Container>;
}

export interface ProjectListItemDto {
    id: string;
    name: string;
    type: ProjectType;
}
