import { Button, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { propFileField, propTextField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { apiUrl } from "../../common/api";
import { ResourceDto } from "./common";
import Image from "mui-image";
import { UseListStore } from "../../common/listStore";

export function ResourceDialog(props: { close: () => void; resource?: ResourceDto; useResourcesStore: UseListStore<ResourceDto>; projectId: string; }) {
    const [useStore] = useState(() => createEntityStore<Partial<ResourceDto>>({
        data: props.resource ?? {
            projectId: props.projectId,
            name: "",
            fileName: "",
        },
        path: "main/admin/resources",
        id: props.resource?.id ?? null,
        fetchOnInit: false,
        onSaved() {
            props.useResourcesStore.getState().fetch(false, true);
            props.close();
        },
    }));
    const { file, id } = useStore(p => ({ file: p.clientData.file, id: p.entityId as string | null }));
    return <>
        <DialogTitle>Resource</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={4}>{propTextField(useStore)("name", "Name")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("fileName", "File name")}</Grid>
                <Grid item xs={4}>{propFileField(useStore)("file", "File")}</Grid>
                <Grid item xs={4}><Paper sx={{ width: "fit-content" }}><Image width={250} height={130} fit="cover"
                    src={file != null ? `data:image/*;base64,${file}` :
                        id != null ? `${apiUrl}main/admin/resources/${id}/content` :
                            ""}
                /></Paper></Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
