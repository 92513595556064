import { Close } from "@mui/icons-material";
import { Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ReactNode, useState } from "react";
import { ifNull } from "./utils";

export function useDialog(renderContent: (close: () => void) => ReactNode, maxWidth: Breakpoint | null = "md") {
    const [visible, setVisible] = useState(false);
    const [onClose, setOnClose] = useState<(() => void) | null>(null);
    function close() {
        setVisible(false);
        if (onClose) {
            onClose();
        }
    }
    return {
        render() {
            return visible && <Dialog maxWidth={ifNull(maxWidth, undefined)} fullWidth open onClose={close}>
                {renderContent(close)}
            </Dialog>;
        },
        show(onClose?: () => void) {
            setOnClose(() => onClose || null);
            setVisible(true);
        }
    };
}

export function renderClose(close: () => void) {
    return <IconButton
        aria-label="close"
        onClick={close}
        sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        }}>
        <Close />
    </IconButton>
}

export function useSimpleDialog(): SimpleDialog {
    const [message, setMessage] = useState<string | null>(null);
    const [customButtons, setCustomButtons] = useState<((close: () => void) => JSX.Element) | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const dialog = useDialog(close => <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ whiteSpace: "pre-line" }}>{message}</DialogContent>
        <DialogActions>
            {customButtons != null ? customButtons(close) : <Button onClick={close}>Ok</Button>}
        </DialogActions>
    </>, null);
    return {
        render: dialog.render,
        show(title: string, message: string, onClose?: () => void, customButtons?: (close: () => void) => JSX.Element) {
            setTitle(title);
            setMessage(message);
            setCustomButtons(() => customButtons || null);
            dialog.show(onClose);
        }
    }
}

export interface SimpleDialog {
    show(title: string, message: string, onClose?: () => void, customButtons?: (close: () => void) => JSX.Element): void;
    render(): JSX.Element | false;
}

export function yesNoButtons(yes: () => void, no?: () => void) {
    return (close: () => void): JSX.Element => <>
        <Button onClick={() => { yes(); close(); }}>Yes</Button>
        <Button onClick={() => { no && no(); close(); }}>No</Button>
    </>;
}
