import { createListStore } from "../../common/listStore";
import { renderListStore } from "../../common/listStoreUi";
import { EmailListItem } from "./common";
import { useStateWithDeps } from "use-state-with-deps";

export function Emails({ projectId, scheduled, sent }: { projectId: string | null; scheduled?: boolean; sent?: boolean; }) {
    const [useStore] = useStateWithDeps(() => createListStore<EmailListItem>({
        path: projectId == null ? undefined : "main/admin/emails",
        qs: {
            projectId,
            scheduled: scheduled ?? false,
            sent: sent ?? false,
        },
        getId: p => p.id,
        listProps: [
            { column: "queueName", label: "Queue", width: 250 },
            { column: "recipientName", label: "Name", width: 250 },
            { column: "recipientEmailAddress", label: "Email", width: 250 },
            { column: "subject", label: "Subject", width: 250 },
            scheduled && { column: "sendAt", label: "Send at", type: "datetime", width: 230 },
            sent && { column: "sentAt", label: "Sent at", type: "datetime", width: 230 },
            sent && { column: "wasReallySent", label: "Really sent", type: "bool", width: 120 },
        ],
    }), [projectId]);
    return <>
        {renderListStore(useStore, {})}
    </>;
}
