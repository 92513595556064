import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: "small"
            }
        },
        MuiSelect: {
            defaultProps: {
                size: "small"
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: "10px !important"
                }
            }
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: true // needed for above "shrink: true" to avoid borders crossing the labels
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "outlined",
            }
        }
    }
});