import { WarningAmber } from "@mui/icons-material";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

export interface SectionData {
    key: string;
    label: string;
    icon?: React.ReactNode;
    content?: () => React.ReactNode;
    subsections?: SectionData[];
    warning?: boolean;
}

export function Sections({ sections, selectedSection, setSelectedSection, expandedSections, setExpandedSections }: {
    sections: SectionData[];
    selectedSection: string;
    setSelectedSection: (section: string) => void;
    expandedSections: string[];
    setExpandedSections: (sections: string[]) => void;
}) {
    return <>
        {sections.map(p => <Section key={p.key} section={p} sections={sections}
            selectedSection={selectedSection} setSelectedSection={setSelectedSection}
            expandedSections={expandedSections} setExpandedSections={setExpandedSections} />)}
    </>;
}

function Section({ sections, section, selectedSection, setSelectedSection, parentSection, expandedSections, setExpandedSections }: {
    sections: SectionData[];
    section: SectionData;
    selectedSection: string;
    setSelectedSection: (section: string) => void;
    parentSection?: string;
    expandedSections: string[];
    setExpandedSections: (sections: string[]) => void;
}) {
    const isSubsectionSelected = section.subsections?.some(p => p.key != null && p.key === selectedSection);
    const isSelected = section.key === selectedSection || isSubsectionSelected;
    return <>
        <ListItem disablePadding>
            <ListItemButton selected={section.key != null && selectedSection === section.key} onClick={() => {
                if (section.subsections == null) {
                    setSelectedSection(section.key);
                }
                if (section.subsections != null) {
                    toggleSection(section.key, selectedSection, expandedSections, sections, setExpandedSections);
                }
            }} style={{ paddingLeft: parentSection ? 30 : undefined }}>
                <ListItemIcon>{section.icon}</ListItemIcon>
                <ListItemText>{section.label}</ListItemText>
                {section.warning && <Typography variant="body2" color="text.secondary"><WarningAmber style={{ marginTop: "2px", marginBottom: "-2px" }} color="warning" /></Typography>}
            </ListItemButton>
        </ListItem>
        {(isSelected || isSubsectionSelected || expandedSections.includes(section.key)) &&
            section.subsections?.map(p => <Section key={p.key} section={p} parentSection={section.key} sections={sections}
                selectedSection={selectedSection} setSelectedSection={setSelectedSection}
                expandedSections={expandedSections} setExpandedSections={setExpandedSections} />)}
    </>;
}

function toggleSection(key: string, selectedSection: string, expandedSections: string[], sections: SectionData[], setExpandedSections: (sections: string[]) => void) {
    const initialExpandedSections = getInitialExpandedSections(sections, selectedSection);
    if (expandedSections.includes(key)) {
        if (!initialExpandedSections.includes(key)) {
            setExpandedSections(expandedSections.filter(p => p !== key));
        }
    } else {
        setExpandedSections([...expandedSections, key]);
    }
}

export function getInitialExpandedSections(sections: SectionData[], selectedSection: string): string[] {
    return sections.flatMap(p => getExpanded(p, selectedSection));
}

function getExpanded(section: SectionData, selectedSection: string, parents: string[] = []): string[] {
    return section.subsections == null ? [] :
        section.subsections.flatMap(p => p.key === selectedSection ? parents.concat(section.key) :
            getExpanded(p, selectedSection, parents.concat(section.key)));
}
