import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { DataGrid } from "../../common/DataGrid";

export function EmailTemplateHelpDialog(props: { close: () => void; }) {
    return <>
        <DialogTitle>Email template help</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataGrid columns={[
                        { label: "Token", prop: "token", width: 200 },
                        { label: "Description", prop: "description", flex: 1 },
                    ]} getId={p => p.token} rows={[
                        { token: "{Title}", description: "Title of the recipient" },
                        { token: "{Forenames}", description: "Forenames of the recipient" },
                        { token: "{Surname}", description: "Surname of the recipient" },
                        { token: "{PreferredName}", description: "Preferred name of the recipient" },
                    ]} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
