import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel } from "@mui/material";
import { renderClose, useDialog } from "../../common/dialog";
import { propTextField } from "../../common/entityStoreForm";
import { useRef, useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { EmailTemplateEditDto, EmailTemplateListItemDto } from "./common";
import { UseListStore } from "../../common/listStore";
import { addOrUpdateItem } from "../../common/utils";
import { EmailEditor, EditorRef, EmailEditorProps } from 'react-email-editor';
import { ResourcesDialog } from "./ResourcesDialog";
import { apiUrl } from "../../common/api";
import { createAxios } from "../../common/axios";
import { EmailTemplateHelpDialog } from "./EmailTemplateHelpDialog";

export function EmailTemplateDialog(props: {
    close: () => void;
    emailTemplate?: EmailTemplateListItemDto;
    useEmailTemplatesStore: UseListStore<EmailTemplateListItemDto>;
    projectId: string;
}) {
    const [useStore] = useState(() => createEntityStore<EmailTemplateEditDto>({
        data: {
            id: null,
            projectId: props.projectId,
            createdAt: "",
            name: props.emailTemplate?.name ?? "",
            subject: "",
            bodyDesign: "",
            bodyHtml: "",
        },
        path: "main/admin/emailTemplates",
        id: props.emailTemplate?.id ?? null,
        allowSaveWithoutChanges: true,
        onSaved(p) {
            addOrUpdateItem(props.useEmailTemplatesStore, p => p.id, p as EmailTemplateListItemDto);
            props.useEmailTemplatesStore.getState().fetch(false, true);
            props.close();
        },
        propValidators: [
        ],
    }));
    const selectImageRef = useRef<any>();
    const resourcesDialog = useDialog(close => <ResourcesDialog close={close} projectId={props.projectId} title="Select image" selectedResource={resource => {
        selectImageRef.current({ url: `${apiUrl}main/admin/resources/${resource.id}/content` });
    }} disableDelete />, "lg");
    const emailEditorRef = useRef<EditorRef>(null);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const onReady: EmailEditorProps['onReady'] = (unlayer) => {
        setIsEditorReady(true);
        const bodyDesign = useStore.getState().clientData.bodyDesign;
        if (bodyDesign != null && bodyDesign !== "") {
            const templateJson = JSON.parse(bodyDesign);
            unlayer.loadDesign(templateJson);
        }
        unlayer.registerCallback('selectImage', (data: any, done: any) => {
            selectImageRef.current = done;
            resourcesDialog.show(() => {
                selectImageRef.current = null;
            });
        });
    };
    const { isSaveDisabled, save } = useStore(p => ({ isSaveDisabled: p.isSaveDisabled, save: p.save }));
    const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
    const helpDialog = useDialog(close => <EmailTemplateHelpDialog close={close} />);
    return <>
        {resourcesDialog.render()}
        {helpDialog.render()}
        <DialogTitle>Email template</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>{propTextField(useStore)("name", "Name")}</Grid>
                <Grid item xs={12}>{propTextField(useStore)("subject", "Subject")}</Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Grid container direction="column">
                            <Grid item xs={12}><InputLabel>Body</InputLabel></Grid>
                            <Grid item xs={12} mt={1.5}>
                                <EmailEditor ref={emailEditorRef} onReady={onReady} />
                            </Grid>
                        </Grid>
                    </FormControl>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => helpDialog.show()}>Help</Button>
            <Button onClick={() => {
                try {
                    setIsSendingTestEmail(true);
                    const unlayer = emailEditorRef.current?.editor;
                    unlayer?.exportHtml((data) => {
                        createAxios().post(`main/admin/emailTemplates/test`, {
                            bodyHtml: data.html,
                            subject: useStore.getState().clientData.subject,
                        });
                    });
                } finally {
                    setTimeout(() => {
                        setIsSendingTestEmail(false);
                    }, 1000);
                }
            }} disabled={!isEditorReady || isSendingTestEmail}>Test</Button>
            <Button onClick={() => {
                const unlayer = emailEditorRef.current?.editor;
                unlayer?.exportHtml((data) => {
                    useStore.getState().setClientDataProp("bodyHtml", data.html);
                    useStore.getState().setClientDataProp("bodyDesign", JSON.stringify(data.design));
                    save();
                });
            }} disabled={!isEditorReady || isSaveDisabled}>Save</Button>
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
