import { createEntityStore } from "./entityStore";

export type LandCode = "GB";

export interface DefaultValuesDto {
    landCode: LandCode;
    language: string;
    currency: string;
}

export const useDefaultValues = createEntityStore<DefaultValuesDto>({
    data: {
        landCode: "GB",
        language: "GB",
        currency: "GBP",
    }
});

export function chunkString(str: string, size: number) {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array<string>(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = str.substring(o, o + size);
    }

    return chunks;
}
