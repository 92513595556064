import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { CloudSync, Email, EmojiEvents, Folder, House, OpenInNew, Payment, People, Tune, VolunteerActivism } from "@mui/icons-material";
import { getProjectAppUrl } from "../../common/api";
import { ProjectDto, ProjectPaymentGateDto } from "../project/common";
import { useDocumentTitle } from "../../layout/page";
import { Resources } from "../project/Resources";
import { SectionData, Sections, getInitialExpandedSections } from "../../common/PageMenu";
import { createAxios } from "../../common/axios";
import { Challenges } from "./Challenges";
import { PaymentGate } from "./PaymentGate";
import { Proxy } from "./Proxy";
import { Emails } from "../project/Emails";
import { EmailSendQueues } from "../project/EmailSendQueues";
import { ProspectGroups } from "../project/ProspectGroups";
import { Prospects } from "../project/Prospects";
import { EmailTemplates } from "../project/EmailTemplates";
import { ProjectUsers } from "../project/ProjectUsers";
import { Donations } from "./Donations";
import { Funds } from "./Funds";
import { MainPage } from "./MainPage";
import { GdProject } from "./GdProject";

export function GdProjectPage() {
    const params = useParams<{ id: string; section?: string; }>();
    const projectId = params.id === "new" ? null : params.id!;
    const navigate = useNavigate();

    const [projectName, setProjectName] = useState<string>();
    const pageTitle = projectId ? `Project ${projectName ?? ""}` : "New project";
    useDocumentTitle(pageTitle);
    useEffect(() => {
        if (projectId != null) {
            createAxios().get<ProjectDto>(`main/admin/gdProjects/${projectId}`).then(p => setProjectName(p.data.name));
        }
    }, [projectId]);

    const [isPaymentGateConfigured, setIsPaymentGateConfigured] = useState<boolean>();
    const updateIsPaymentGateConfigured = useCallback(async () => {
        const { data } = await createAxios().get<ProjectPaymentGateDto>(`main/admin/gdProjects/${projectId}/paymentGate`)
        setIsPaymentGateConfigured(data.isConfigured);
    }, [projectId, setIsPaymentGateConfigured]);
    useEffect(() => {
        if (projectId != null) {
            updateIsPaymentGateConfigured();
        }
    }, [projectId, updateIsPaymentGateConfigured]);

    const selectedSection = params.section ?? "main";
    function setSelectedSection(p: string) {
        navigate(`/projects/gd/${params.id}${p === "main" ? "" : "/" + p}`);
    }

    const sections = useMemo(() => {
        return getSections(projectId, isPaymentGateConfigured);
    }, [projectId, isPaymentGateConfigured]);

    const [expandedSections, setExpandedSections] = useState<string[]>(
        () => getInitialExpandedSections(sections, selectedSection));

    return <>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Box sx={{ width: 250, flexShrink: 0, bgcolor: "#f4f4f4", flexDirection: "column" }}>
                <List subheader={
                    <ListSubheader disableSticky style={{ lineHeight: "25px", marginTop: "8px", marginBottom: "5px" }}>
                        {pageTitle}
                    </ListSubheader>
                }>
                    <Sections sections={sections} selectedSection={selectedSection} setSelectedSection={setSelectedSection}
                        expandedSections={expandedSections} setExpandedSections={setExpandedSections} />
                    {projectId != null && <>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton component={RouterLink} target="_blank" to={getProjectAppUrl(projectId as string)}>
                                <ListItemIcon><OpenInNew /></ListItemIcon>
                                <ListItemText>App</ListItemText>
                            </ListItemButton>
                        </ListItem></>}
                </List>
            </Box>
            <Box sx={{ flexGrow: 1, mt: 4, ml: 2, mb: 2, mr: 2 }}>
                <Grid container>
                    {selectedSection === "main" && <Grid item xs={12}>
                        <GdProject projectId={projectId} />
                    </Grid>}
                    {selectedSection === "funds" && <Grid item xs={12}>
                        <Funds projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "users" && <Grid item xs={12}>
                        <ProjectUsers projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "donations" && <Grid item xs={12}>
                        <Donations projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "emailTemplates" && <Grid item xs={12}>
                        <EmailTemplates projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "resources" && <Grid item xs={12}>
                        <Resources projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "prospects" && <Grid item xs={12}>
                        <Prospects projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "prospectGroups" && <Grid item xs={12}>
                        <ProspectGroups projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "emailSendQueues" && <Grid item xs={12}>
                        <EmailSendQueues projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "scheduledEmails" && <Grid item xs={12}>
                        <Emails projectId={projectId!} scheduled />
                    </Grid>}
                    {selectedSection === "sentEmails" && <Grid item xs={12}>
                        <Emails projectId={projectId!} sent />
                    </Grid>}
                    {selectedSection === "proxy" && <Grid item xs={12}>
                        <Proxy projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "paymentGate" && <Grid item xs={12}>
                        <PaymentGate projectId={projectId!}
                            updateIsPaymentGateConfigured={updateIsPaymentGateConfigured}
                            isPaymentGateConfigured={isPaymentGateConfigured} />
                    </Grid>}
                    {selectedSection === "mainPage" && <Grid item xs={12}>
                        <MainPage projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "challenges" && <Grid item xs={12}>
                        <Challenges projectId={projectId!} />
                    </Grid>}
                </Grid>
            </Box>
        </Box>
    </>;
}

function getSections(projectId: string | null, isPaymentGateConfigured: boolean | undefined): SectionData[] {
    const isNew = projectId == null;
    return [
        { key: "main", label: "Project", icon: <Tune /> },
        !isNew && { key: "mainPage", label: "Main page", icon: <House /> },
        !isNew && { key: "funds", label: "Funds", icon: <VolunteerActivism /> },
        !isNew && { key: "challenges", label: "Challenges", icon: <EmojiEvents /> },
        !isNew && { key: "users", label: "Users", icon: <People /> },
        !isNew && { key: "donations", label: "Donations", icon: <Payment /> },
        !isNew && { key: "prospects", label: "Prospects", icon: <People /> },
        !isNew && { key: "prospectGroups", label: "Prospect groups", icon: <People /> },
        !isNew && {
            key: "emails", label: "Emails", icon: <Email />, subsections: [
                { key: "sentEmails", label: "Sent" },
                { key: "scheduledEmails", label: "Scheduled" },
                { key: "emailTemplates", label: "Templates" },
                { key: "emailSendQueues", label: "Send queues" },
            ]
        },
        !isNew && { key: "resources", label: "Resources", icon: <Folder /> },
        !isNew && { key: "proxy", label: "Reverse proxy", icon: <CloudSync /> },
        !isNew && { key: "paymentGate", label: "Payment gate", icon: <Payment />, warning: isPaymentGateConfigured === false },
    ].filter(p => p) as SectionData[];
}
