import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { Grid, Link as MuiLink, Alert } from "@mui/material";
import { propTextField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { apiUrl } from "../../common/api";
import { ProjectPaymentGateDto } from "../project/common";

export function PaymentGate({ projectId, updateIsPaymentGateConfigured, isPaymentGateConfigured }: {
    projectId: string;
    updateIsPaymentGateConfigured: () => void;
    isPaymentGateConfigured: boolean | undefined;
}) {
    const [useStore] = useState(() => createEntityStore<Partial<ProjectPaymentGateDto>>({
        path: `main/admin/gdProjects/${projectId}/paymentGate`, data: {
            stripeApiKeyPublishable: "",
            stripeApiKeySecret: "",
            stripeWebhookSecret: "",
        },
        propValidators: [],
        onSaved: () => updateIsPaymentGateConfigured(),
    }));
    return <Grid container spacing={2} maxWidth="md">
        <Grid item xs={12}>
            We use Stripe for payment processing.
        </Grid>
        {!isPaymentGateConfigured && <Grid item xs={12}>
            <Alert severity="warning">Payment gate is not properly configured. Internal test configuration is used currently.</Alert>
        </Grid>}
        <Grid item xs={12}>
            While testing online payments in Stripe test mode, use <MuiLink href="https://stripe.com/docs/testing?testing-method=card-numbers#visa">Stripe test card numbers</MuiLink>.
        </Grid>
        <Grid item xs={12}>
            Get Stripe API keys at <MuiLink href="https://dashboard.stripe.com/apikeys">Stripe API keys page (live)</MuiLink> or  <MuiLink href="https://dashboard.stripe.com/test/apikeys">Stripe API keys page (test)</MuiLink>.
        </Grid>
        <Grid item xs={12}>{propTextField(useStore)("stripeApiKeyPublishable", "Stripe API key (publishable)")}</Grid>
        <Grid item xs={12}>{propTextField(useStore)("stripeApiKeySecret", "Stripe API key (secret)")}</Grid>
        <Grid item xs={12}>
            Configure webhook at <MuiLink href="https://dashboard.stripe.com/webhooks">Stripe webhooks page (live)</MuiLink> or  <MuiLink href="https://dashboard.stripe.com/test/webhooks">Stripe webhooks page (test)</MuiLink>.<br />
            Webhook URL: {`${apiUrl}gd/stripeWebhook?projectId=${projectId}`}<br />
            Events to send: <code>payment_intent.succeeded</code>
        </Grid>
        <Grid item xs={12}>{propTextField(useStore)("stripeWebhookSecret", "Stripe webhook secret")}</Grid>
        <Grid item xs={12} mt={2}>
            <EntityStoreSaveButton useStore={useStore} />
        </Grid>
    </Grid>;
}
