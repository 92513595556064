import { createAxios } from "./axios";
import { SimpleDialog, useSimpleDialog, yesNoButtons } from "./dialog";
import { UseEntityStore } from "./entityStore";
import { UseListStore } from "./listStore";
import { KeysOfType } from "./utils";

export function useListItemDeleteApi<T>(useStore: UseListStore<T>, getPath: (item: T) => string, useMainStore?: UseEntityStore<any>) {
    const dialog = useSimpleDialog();
    async function deleteItem() {
        dialog.show("Confirm deletion", "Delete selected item?", undefined, yesNoButtons(async () => {
            try {
                await createAxios().delete(getPath(useStore.getState().selectedItem!));
            }
            finally {
                if (useMainStore != null) {
                    useMainStore.getState().fetchServerData();
                } else {
                    useStore.getState().fetch(false, true);
                }
            }
        }));
    }

    return { dialog, deleteItem };
}

export function getEntityListPropItemDeleteCallback<T, V, K extends KeysOfType<T, V[]>>(
    simpleDialog: SimpleDialog,
    useStore: UseEntityStore<T>,
    selectedItem: V | null | undefined,
    prop: K,
    getId: (item: V) => number | string,
    onDelete?: (item: V) => void,
) {
    return async function deleteItem() {
        simpleDialog.show("Confirm deletion", "Delete selected item?", undefined, yesNoButtons(async () => {
            const items = useStore.getState().clientData[prop] as V[];
            const selectedItemId = getId(selectedItem!);
            useStore.getState().setClientDataProp(prop, items.filter(p => getId(p) !== selectedItemId) as T[K]);
            onDelete?.(selectedItem!);
        }));
    }
}
