import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { Grid, Paper } from "@mui/material";
import { propBoolField, propDateField, propEnumField, propFileField, propTextField } from "../../common/entityStoreForm";
import { Link, useNavigate } from "react-router-dom";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { propRequired, propValueValidator } from "../../common/entityStoreValidators";
import { apiUrl } from "../../common/api";
import { useAccountStore } from "../../common/account";
import Image from "mui-image";
import { useCountries } from "../../common/country";
import { isEmailListValid } from "../../common/utils";
import { GdProjectEditDto } from "./common";

export function GdProject({ projectId }: { projectId: string | null; }) {
    const navigate = useNavigate();
    const isGlobalAdmin = useAccountStore(p => (({ isGlobalAdmin: p.userInfo?.isGlobalAdmin ?? false })));
    const [useStore] = useState(() => createEntityStore<GdProjectEditDto>({
        path: `main/admin/gdProjects`, data: {
            name: "",
            pageTitle: "",
            start: null,
            country: "GB",
            favIconResourceId: null,
            bgImageResourceId: null,
            isTrial: false,
            testEmails: "",
        },
        id: projectId,
        getNewId: p => p.id!,
        propValidators: [
            propRequired("name"),
            propRequired("pageTitle"),
            propValueValidator("testEmails", p => !isEmailListValid(p, 5) && "Invalid value."),
        ],
        onCreated(entity) {
            navigate(`/projects/gd/${entity.id}`, { replace: true });
        },
    }));
    const store = useStore(p => ({
        name: p.serverData.name,
        favIconResource: p.clientData.favIconResource,
        favIconResourceId: p.clientData.favIconResourceId,
        bgImageResource: p.clientData.bgImageResource,
        bgImageResourceId: p.clientData.bgImageResourceId,
        isTrial: p.clientData.isTrial,
    }));
    return <Grid container spacing={2} maxWidth="md">
        <Grid item xs={6}>{propTextField(useStore)("name", "Name", { multiline: true })}</Grid>
        <Grid item xs={6}>{propTextField(useStore)("pageTitle", "Page title", { multiline: true })}</Grid>
        <Grid item xs={6}>{propDateField(useStore)("start", "startFormatted", "Start", { type: "datetime" })}</Grid>
        <Grid item xs={6}>{propEnumField(useStore)("country", "Country", useCountries)}</Grid>
        <Grid item xs={6}>{propFileField(useStore)("favIconResource", "Favicon (ico/png/gif/jpeg/svg)")}</Grid>
        <Grid item xs={6}><Paper sx={{ width: "fit-content" }}><Image width={250} height={130} fit="cover"
            src={store.favIconResource != null ?
                `data:image/*;base64,${store.favIconResource}` :
                store.favIconResourceId != null ?
                    `${apiUrl}main/admin/resources/${store.favIconResourceId}/content` :
                    ""} /></Paper></Grid>
        <Grid item xs={6}>{propFileField(useStore)("bgImageResource", "Background image")}</Grid>
        <Grid item xs={6}><Paper sx={{ width: "fit-content" }}><Image width={250} height={130} fit="cover"
            src={store.bgImageResource != null ?
                `data:image/*;base64,${store.bgImageResource}` :
                store.bgImageResourceId != null ?
                    `${apiUrl}main/admin/resources/${store.bgImageResourceId}/content` :
                    ""} /></Paper></Grid>
        <Grid item xs={12}>{propBoolField(useStore)("isTrial", "Trial mode", { disabled: !isGlobalAdmin })}
            <p>
                In trial mode, emails are sent only to <Link to={`/projects/${projectId}/users`}>project users</Link> and test email addresses.
            </p>
            <p>
                In non-trial mode, if any test email addresses are specified, emails are sent only to <Link to={`/projects/${projectId}/users`}>project users</Link> and test email addresses.
            </p>
        </Grid>
        <Grid item xs={12}>{propTextField(useStore)("testEmails", "Test email addresses (comma separated, 5 max)")}</Grid>
        <Grid item xs={12} mt={2}>
            <EntityStoreSaveButton useStore={useStore} />
        </Grid>
    </Grid>;
}
