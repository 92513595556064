import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useDialog } from "../../common/dialog";
import { createListStore } from "../../common/listStore";
import { listHeaderItems, renderListStore } from "../../common/listStoreUi";
import { useListItemDeleteApi } from "../../common/entityDelete";
import { ProspectGroupDto } from "./common";
import { useStateWithDeps } from "use-state-with-deps";
import { ProspectGroupDialog } from "./ProspectGroupDialog";

export function ProspectGroups({ projectId }: { projectId: string | null; }) {
    const [useStore] = useStateWithDeps(() => createListStore<ProspectGroupDto>({
        path: projectId == null ? undefined : "main/admin/prospectGroups?projectId=" + projectId,
        getId: p => p.id,
        listProps: [
            { column: "name", label: "Name", width: 300 },
            { column: "createdAt", label: "Created at", width: 300, type: "datetime" },
        ],
    }), [projectId]);
    const store = useStore(p => ({ selectedItem: p.selectedItem }));
    const addDialog = useDialog(close => <ProspectGroupDialog close={close} projectId={projectId!} useProspectGroupsStore={useStore} />, "lg");
    const editDialog = useDialog(close => <ProspectGroupDialog close={close} prospectgroup={store.selectedItem!} projectId={projectId!} useProspectGroupsStore={useStore} />, "lg");
    const deleteItem = useListItemDeleteApi(useStore, item => `main/admin/prospectGroups/${item.id}`);
    return <>
        {addDialog.render()}
        {editDialog.render()}
        {deleteItem.dialog.render()}
        {renderListStore(useStore, {
            header: listHeaderItems([
                ["add", () => <Button title="Add" onClick={() => addDialog.show()} disabled={projectId == null}><Add /></Button>],
                ["edit", () => <Button disabled={store.selectedItem == null} title="Edit" onClick={() => editDialog.show()}><Edit /></Button>],
                ["remove", () => <Button disabled={store.selectedItem == null} title="Delete" onClick={() => deleteItem.deleteItem()}><Delete /></Button>],
            ]),
            onDoubleClick: () => editDialog.show(),
        })}
    </>;
}
