export function StoreWrapper<T>(props: {
    useStore(): T;
    render(store: T): JSX.Element | null;
}) {
    const store = props.useStore();
    return props.render(store);
}

export interface StoreData<T> {
    data: T;
    isFetching: boolean;
}
