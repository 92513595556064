import { create } from "zustand";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { createAxios } from "./axios";

export const accessTokenKey = "accessToken";
const RefreshAccessTokenBeforeExpirationMinutes = 60;
export const minPasswordLength = 6;

export const useAccountStore = create<AccountStore>((set, get) => ({
    ...getTokenData(localStorage.getItem(accessTokenKey)),
    isTokenBeingRefreshed: false,
    setAccessToken: (accessToken) => {
        set(getTokenData(accessToken));
        if (localStorage.getItem(accessTokenKey) !== accessToken) {
            if (accessToken == null) {
                localStorage.removeItem(accessTokenKey);
            } else {
                localStorage.setItem(accessTokenKey, accessToken);
            }
        }
    },
    getAndRefreshAccessToken: () => {
        const token = get().accessToken;
        if (token == null) {
            return null;
        }
        if (!get().isTokenBeingRefreshed && isTokenExpired(token, RefreshAccessTokenBeforeExpirationMinutes * 60)) {
            set({ isTokenBeingRefreshed: true });
            (async () => {
                try {
                    const { data } = await createAxios().post("main/common/account/refreshToken");
                    get().setAccessToken(data?.accessToken || null);
                } catch (err) {
                    if (isResponseUnauthorized(err)) {
                        get().setAccessToken(null);
                    }
                } finally {
                    set({ isTokenBeingRefreshed: false });
                }
            })();
        }
        return token;
    },
}));

window.addEventListener("storage", e => {
    const state = useAccountStore.getState();
    if (e.key === accessTokenKey && e.newValue !== state.accessToken) {
        state.setAccessToken(e.newValue);
    }
});

useAccountStore.getState().getAndRefreshAccessToken();

export interface UserInfo {
    isGlobalAdmin: boolean;
    name: string;
}

function getUserInfo(accessToken: string | null): UserInfo | null {
    if (accessToken == null) {
        return null;
    }

    const decoded = jwtDecode<{
        IsGlobalAdmin: boolean;
        name: string;
    }>(accessToken);
    return { isGlobalAdmin: decoded.IsGlobalAdmin, name: decoded.name };
}

export function isResponseUnauthorized(err: any) {
    return err?.response?.status === 401;
}

export interface AccountStore {
    accessToken: string | null;
    userId: number | null;
    isTokenBeingRefreshed: boolean;
    userInfo: UserInfo | null;
    setAccessToken: (token: string | null) => void;
    getAndRefreshAccessToken: () => string | null;
}

function isTokenExpired(token: string, offsetSeconds = 0) {
    try {
        const { exp } = jwtDecode<JwtPayload>(token);
        const expirationDatetimeInMs = (exp || Number.MAX_VALUE) * 1000;
        return Date.now() >= expirationDatetimeInMs - offsetSeconds * 1000;
    } catch {
        return true;
    }
}

function getTokenData(accessToken: string | null) {
    let userId: AccountStore["userId"] = null;
    if (accessToken != null) {
        const decoded = jwtDecode<{ sub: string; }>(accessToken);
        userId = Number(decoded.sub);
    }
    return { accessToken, userId, userInfo: getUserInfo(accessToken) };
}
