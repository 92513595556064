import { useEffect, useState } from "react";
import { ResourceDto } from "./common";
import { createListStore } from "../../common/listStore";
import { useStateWithDeps } from "use-state-with-deps";
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useDialog } from "../../common/dialog";
import { ResourceDialog } from "./ResourceDialog";
import { Add } from "@mui/icons-material";
import { apiUrl } from "../../common/api";
import { useListItemDeleteApi } from "../../common/entityDelete";
import { formatDateTime } from "../../common/localization";

const imageMimeTypes = ["image/jpeg", "image/png", "image/gif"];

export function getUseResourcesStore(projectId: string, mimeTypes?: string[]) {
    const [useStore] = useStateWithDeps(() => createListStore<ResourceDto>({
        path: "main/admin/resources?projectId=" + projectId,
        getId: p => p.id,
        listProps: [
            { column: "id", label: "Id", width: 340 },
            { column: "mimeType", label: "MIME type", width: 200 },
            { column: "name", label: "Name", width: 200 },
            { column: "fileName", label: "File name", width: 200 },
            { column: "createdAt", label: "Created at", type: "datetime", width: 180 },
        ]
    }), [projectId]);
    useEffect(() => {
        if (mimeTypes == null) {
            useStore.getState().setExternalDataFilters([]);
        } else {
            useStore.getState().setExternalDataFilters([
                { props: ["mimeType"], operator: "In", value: mimeTypes },
            ]);
        }
    }, [mimeTypes, useStore]);
    return useStore;
}

export function Resources(props: {
    selectedResource?: (resource: ResourceDto) => void;
    projectId: string;
    disableDelete?: boolean;
}) {
    const useStore = getUseResourcesStore(props.projectId);
    const [selectedResource, setSelectedResource] = useState<ResourceDto | undefined>(undefined);
    const addResourceDialog = useDialog(close => <ResourceDialog close={close} useResourcesStore={useStore} projectId={props.projectId!} />);
    const editResourceDialog = useDialog(close => <ResourceDialog close={close} useResourcesStore={useStore} projectId={props.projectId!} resource={selectedResource} />);
    const { items } = useStore(p => ({ items: p.items }));
    const deleteProject = useListItemDeleteApi(useStore, item => `main/admin/resources/${item.id}`);
    const deleteResource = async (resource: ResourceDto) => {
        useStore.getState().setSelectedItem(resource);
        deleteProject.deleteItem();
    };
    const editResource = (resource: ResourceDto) => {
        setSelectedResource(resource);
        editResourceDialog.show();
    };
    return <>
        {addResourceDialog.render()}
        {editResourceDialog.render()}
        {deleteProject.dialog.render()}
        <Button title="Add" onClick={() => addResourceDialog.show()} disabled={props.projectId == null}><Add /></Button>
        <Grid container spacing={2} mt={1}>
            {items.map(p =>
                <Grid item width={350} key={p.id}>
                    <Card raised>
                        <CardMedia
                            sx={{ height: 200, backgroundSize: "contain" }}
                            image={imageMimeTypes.includes(p.mimeType) ? `${apiUrl}main/admin/resources/${p.id}/content` : undefined}
                            onClick={!props.selectedResource ? undefined : () => {
                                props.selectedResource!(p);
                            }}
                            style={{ cursor: props.selectedResource ? "pointer" : undefined }}
                        />
                        {(p.name || p.fileName) && <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                                {p.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" component="div">
                                {p.fileName && <>File name: {p.fileName}</>}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" component="div">
                                Created at: {formatDateTime(p.createdAt)}
                            </Typography>
                        </CardContent>}
                        <CardActions>
                            <Button onClick={() => editResource(p)}>Edit</Button>
                            {!props.disableDelete && <Button onClick={() => deleteResource(p)}>Delete</Button>}
                            {props.selectedResource &&
                                <Button onClick={() => props.selectedResource!(p)}>Select</Button>}
                        </CardActions>
                    </Card>
                </Grid>
            )}
        </Grid>
    </>;
}
