import { AppBar, Box, Button, ButtonBase, GlobalStyles, IconButton, Menu, MenuItem, MenuProps, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { RequireAuth } from "./RequireAuth";
import { useAccountStore } from "../common/account";
import { DropdownMenu } from "../common/DropdownMenu";
import { Person2Rounded } from "@mui/icons-material";
import { useDialog } from "../common/dialog";
import { ChangePasswordDialog } from "../feature/account/ChangePasswordDialog";

function button(to: string, label: string) {
    return <Button color="inherit" variant="text" disableElevation component={Link} to={to}>{label}</Button>;
}

function linkMenuItem(close: () => void) {
    return (to: string, label: string) => <MenuItem onClick={close} component={Link} to={to}>{label}</MenuItem>;
}

function actionMenuItem(close: () => void) {
    return (label: string, action: () => void) => <MenuItem onClick={() => { close(); action(); }}>{label}</MenuItem>;
}

function iconDropdownButton(icon: JSX.Element) {
    return (open: (event: React.MouseEvent<HTMLElement>) => void) => <IconButton onClick={open} color="inherit">{icon}</IconButton>;
}

function iconDropdownMenu(icon: JSX.Element, menu: (menuProps: MenuProps, itemTemplate: ReturnType<typeof linkMenuItem>, close: () => void) => JSX.Element) {
    return <DropdownMenu triggerElement={iconDropdownButton(icon)} itemTemplate={linkMenuItem} menu={menu} />;
}

export function AuthLayout() {
    const { isGlobalAdmin, name } = useAccountStore(p => (({
        isGlobalAdmin: p.userInfo?.isGlobalAdmin ?? false,
        name: p.userInfo?.name ?? "",
    })));
    const changePasswordDialog = useDialog(close => <ChangePasswordDialog close={close} />, "xs");
    return <RequireAuth>
        {changePasswordDialog.render()}
        <AppBar position="static" sx={{ p: "0 8px", zIndex: 1 }}>
            <Toolbar variant="dense" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <ButtonBase component={Link} to="/" ><Typography variant="h6" p="2px 8px" mt="-4px">Jarise</Typography></ButtonBase>
                <Box flexGrow={1} display="flex">
                    {button("/projects", "Projects")}
                    {isGlobalAdmin && button("/users", "Users")}
                </Box>
                {iconDropdownMenu(<Person2Rounded />, (menuProps, _, close) => <Menu {...menuProps}>
                    <MenuItem divider>{name}</MenuItem>
                    {actionMenuItem(close)("Change password", () => {
                        changePasswordDialog.show();
                    })}
                    {actionMenuItem(close)("Logout", () => {
                        useAccountStore.getState().setAccessToken(null);
                    })}
                </Menu>)}
            </Toolbar>
        </AppBar>
        <Outlet />
        <GlobalStyles styles={{
            html: { height: "100%" },
            body: { height: "100%" },
            "#root": {
                display: "flex",
                flexDirection: "column",
                height: "100%",
            },
        }} />
    </RequireAuth>;
}
