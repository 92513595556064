import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { propBoolField, propNumberField, propTextField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { ChallengeDto, ChallengeEditDto } from "../project/common";
import { propCurrency, propInt, propRequired, propValidValue } from "../../common/entityStoreValidators";
import { UseListStore } from "../../common/listStore";

export function ChallengeDialog(props: { close: () => void; challenge?: ChallengeDto; useChallengesStore: UseListStore<ChallengeDto>; projectId: string; }) {
    const [useStore] = useState(() => createEntityStore<ChallengeEditDto>({
        data: props.challenge ?? {
            projectId: props.projectId,
            name: "",
            description: "",
            fromAbroad: false,
            beforeStart: false,
            parents: false,
        } as ChallengeDto,
        path: "main/admin/challenges",
        id: props.challenge?.id ?? null,
        fetchOnInit: false,
        onSaved() {
            props.useChallengesStore.getState().fetch(false, true);
            props.close();
        },
        propValidators: [
            propValidValue("order", "orderFormatted"),
            propValidValue("donors", "donorsFormatted"),
            propValidValue("matriculatedFrom", "matriculatedFromFormatted"),
            propValidValue("additionalDonation", "additionalDonationFormatted"),
            propInt("order"),
            propInt("donors"),
            propInt("matriculatedFrom"),
            propCurrency("additionalDonation"),
            propRequired("name"),
            propRequired("donors"),
        ],
    }));
    return <>
        <DialogTitle>Challenge</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={4}>{propTextField(useStore)("name", "Name")}</Grid>
                <Grid item xs={4}>{propNumberField(useStore)("order", "orderFormatted", "Order")}</Grid>
                <Grid item xs={12}>{propTextField(useStore)("description", "Description")}</Grid>
                <Grid item xs={4}>{propNumberField(useStore)("donors", "donorsFormatted", "Donors")}</Grid>
                <Grid item xs={4}>{propNumberField(useStore)("donated", "donatedFormatted", "Donated", { isCurrency: true })}</Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>{propNumberField(useStore)("matriculatedFrom", "matriculatedFromFormatted", "Matriculated from [year]")}</Grid>
                <Grid item xs={4}>{propNumberField(useStore)("additionalDonation", "additionalDonationFormatted", "Additional donation on completion", { isCurrency: true })}</Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>{propBoolField(useStore)("fromAbroad", "From abroad")}</Grid>
                <Grid item xs={4}>{propBoolField(useStore)("beforeStart", "Before Giving Day begins")}</Grid>
                <Grid item xs={4}>{propBoolField(useStore)("parents", "Donors are parents")}</Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
