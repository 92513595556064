import { of } from "rxjs";
import { createListStore } from "./listStore";

export interface EnumItemDto {
    id: number | string;
    name: string;
    inactive?: boolean;
}

export enum ProjectType {
    GivingDays = 1,
    TeleWizard = 2,
}

export enum TwProjectUserRole {
    Supervisor = 1,
    Caller = 2,
}

export const projectTypes: EnumItemDto[] = [
    { id: ProjectType.GivingDays, name: "Giving days" },
    { id: ProjectType.TeleWizard, name: "TeleWizard" },
];

export const twProjectUserRoles: EnumItemDto[] = [
    { id: TwProjectUserRole.Supervisor, name: "Supervisor" },
    { id: TwProjectUserRole.Caller, name: "Caller" },
];

export const useProjectTypesStore = createListStore({ data: of({ data: projectTypes, isFetching: false }), getId: p => p.id });
export const useTwProjectUserRolesStore = createListStore({ data: of({ data: twProjectUserRoles, isFetching: false }), getId: p => p.id });
