import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useDialog } from "../../common/dialog";
import { createListStore } from "../../common/listStore";
import { listHeaderItems, renderListStore } from "../../common/listStoreUi";
import { useListItemDeleteApi } from "../../common/entityDelete";
import { ProspectDto } from "./common";
import { useStateWithDeps } from "use-state-with-deps";
import { ProspectDialog } from "./ProspectDialog";
import { ProspectImportDialog } from "./ProspectImportDialog";
import { downloadFromApiPost } from "../../common/download";

export function Prospects({ projectId }: { projectId: string | null; }) {
    const [useStore] = useStateWithDeps(() => createListStore<ProspectDto>({
        path: projectId == null ? undefined : "main/admin/prospects?projectId=" + projectId,
        getId: p => p.id,
        listProps: [
            { column: "forenames", label: "Forenames", width: 300 },
            { column: "surname", label: "Surname", width: 300 },
            { column: "email", label: "E-mail", width: 300 },
            { column: "prospectId", label: "Prospect ID", width: 300 },
        ],
    }), [projectId]);
    const store = useStore(p => ({ selectedItem: p.selectedItem }));
    const addDialog = useDialog(close => <ProspectDialog close={close} projectId={projectId!} useProspectsStore={useStore} />);
    const editDialog = useDialog(close => <ProspectDialog close={close} prospect={store.selectedItem!} projectId={projectId!} useProspectsStore={useStore} />);
    const deleteItem = useListItemDeleteApi(useStore, item => `main/admin/prospects/${item.id}`);
    const importDialog = useDialog(close => <ProspectImportDialog close={close} projectId={projectId!} useProspectsStore={useStore} />);
    async function doExport() {
        await downloadFromApiPost("main/admin/prospects/export", { projectId });
    }
    return <>
        {addDialog.render()}
        {editDialog.render()}
        {deleteItem.dialog.render()}
        {importDialog.render()}
        {renderListStore(useStore, {
            header: listHeaderItems([
                ["add", () => <Button title="Add" onClick={() => addDialog.show()} disabled={projectId == null}><Add /></Button>],
                ["edit", () => <Button disabled={store.selectedItem == null} title="Edit" onClick={() => editDialog.show()}><Edit /></Button>],
                ["remove", () => <Button disabled={store.selectedItem == null} title="Delete" onClick={() => deleteItem.deleteItem()}><Delete /></Button>],
                ["import", () => <Button title="Import prospects and groups" onClick={() => importDialog.show()}>Import</Button>],
                ["export", () => <Button title="Export prospects and groups" onClick={doExport}>Export</Button>],
            ]),
            onDoubleClick: () => editDialog.show(),
        })}
    </>;
}
