import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { ResourceDto } from "./common";
import { Resources } from "./Resources";

export function ResourcesDialog(props: {
    close: () => void;
    projectId: string;
    selectedResource: (resource: ResourceDto) => void;
    title: string;
    disableDelete?: boolean;
}) {
    return <>
        <DialogTitle>{props.title}</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Resources projectId={props.projectId} disableDelete={props.disableDelete}
                        selectedResource={p => {
                            props.selectedResource(p);
                            props.close();
                        }} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
