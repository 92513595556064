import saveAs from "file-saver";
import { createAxios } from "./axios";
import parser from "content-disposition-parser";

export function arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export async function downloadFromApi(path: string) {
    const result = await createAxios().get<Blob>(path, { responseType: "blob" });
    const cd = result.headers["content-disposition"];
    const fileName = cd == null ? undefined : parser(cd).filename as string;
    saveAs(result.data, fileName);
}

export async function downloadFromApiPost(path: string, data: any) {
    const result = await createAxios().post<Blob>(path, data, { responseType: "blob" });
    const cd = result.headers["content-disposition"];
    const fileName = cd == null ? undefined : parser(cd).filename as string;
    saveAs(result.data, fileName);
}
