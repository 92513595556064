import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { propBoolField, propCustomEnumField, propDateField, propTextField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { EmailSendQueueDto, EmailSendQueueSaveDto, EmailTemplateListItemDto, ProspectGroupDto } from "./common";
import { UseListStore, createListStore } from "../../common/listStore";
import { useStateWithDeps } from "use-state-with-deps";
import { isoUtcDateTimeFormat } from "../../common/utils";
import dayjs from "dayjs";
import { propRequired, propValidValue } from "../../common/entityStoreValidators";

export function EmailSendQueueDialog(props: { close: () => void; emailsendqueue?: EmailSendQueueDto; projectId: string; useEmailSendQueuesStore: UseListStore<EmailSendQueueDto>; }) {
    const [useStore] = useState(() => createEntityStore<EmailSendQueueSaveDto>({
        data: props.emailsendqueue ?? {
            projectId: props.projectId,
            name: "",
            sendAt: dayjs().utc().format(isoUtcDateTimeFormat),
            isActive: true,
            deactivateOnSendingFinished: true,
        } as EmailSendQueueSaveDto,
        path: "main/admin/emailSendQueues",
        id: props.emailsendqueue?.id ?? null,
        fetchOnInit: false,
        onSaved() {
            props.useEmailSendQueuesStore.getState().fetch(false, true);
            props.close();
        },
        propValidators: [
            propRequired("name"),
            propValidValue("sendAt", "sendAtFormatted"),
            propRequired("sendAt"),
            propRequired("emailTemplateId"),
            propRequired("prospectGroupId"),
        ],
    }));
    const [useEmailTemplatesStore] = useStateWithDeps(() => createListStore<EmailTemplateListItemDto>({
        path: "main/admin/emailTemplates?projectId=" + props.projectId,
        getId: p => p.id,
    }), [props.projectId]);
    const [useProspectGroupsStore] = useStateWithDeps(() => createListStore<ProspectGroupDto>({
        path: "main/admin/prospectGroups?projectId=" + props.projectId,
        getId: p => p.id,
    }), [props.projectId]);
    return <>
        <DialogTitle>Email send queue</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={6}>{propTextField(useStore)("name", "Name")}</Grid>
                <Grid item xs={6}>{propDateField(useStore)("sendAt", "sendAtFormatted", "Send at", { type: "datetime" })}</Grid>
                <Grid item xs={6}>{propCustomEnumField(useStore)("emailTemplateId", "Email template", useEmailTemplatesStore, {
                    getId: p => p.id, getName: p => p.name,
                })}</Grid>
                <Grid item xs={6}>{propCustomEnumField(useStore)("prospectGroupId", "Prospect group", useProspectGroupsStore, {
                    getId: p => p.id, getName: p => p.name,
                })}</Grid>
                <Grid item xs={6}>{propBoolField(useStore)("isActive", "Active")}</Grid>
                <Grid item xs={6}>{propBoolField(useStore)("deactivateOnSendingFinished", "Deactivate on sending finished")}</Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
