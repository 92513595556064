import { useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { chunkString, DefaultValuesDto, LandCode } from "./defaultValues";
import { EnumItemDto } from "./enum";
import { keyBy } from "lodash";
import { UseListStore } from "./listStore";
import { Subscription } from "rxjs";
import { useState, useSyncExternalStore } from "react";
import { validate } from "email-validator";

export type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// https://stackoverflow.com/questions/51476103/enforcing-type-of-key-in-generic-function-input
export type KeysOfTypeSet<T, U> = { [K in keyof T]: T[K] extends U ? K : never }[keyof T] & string;
export type KeysOfTypeGet<T, U> = { [K in keyof T]: U extends T[K] ? K : never }[keyof T] & string;
export type KeysOfType<T, U> = { [K in keyof T]: T[K] extends U ? U extends T[K] ? K : never : never }[keyof T] & string;

export function ifNull<T>(value: T | null | undefined, other: T) {
    return value == null ? other : value;
}

export function containsDigit(text: string) {
    return /\d/.test(text);
}

export function isDigitsOnly(text: string) {
    return /^\d*$/.test(text);
}

export function landCodes(...landCodes: LandCode[]) {
    return Array<LandCode | undefined>(...landCodes);
}

export const isoDateFormat = "YYYY-MM-DD";
export const isoUtcDateTimeFormat = "YYYY-MM-DDTHH:mm:ss[Z]";
export const isoMonthFormat = "YYYY-MM";

let lastKey = 0;

export function withSameParam<T>(paramName: string, component: (changingParam: (newValue: string) => void) => (props: T) => JSX.Element) {
    let values: (string | undefined)[] = [];
    let key = ++lastKey;
    let isInitialized = false;
    const Comp = component(newValue => {
        values.push(newValue);
    });
    return (props: T): JSX.Element => {
        const paramValue = useParams()[paramName];
        if (!isInitialized) {
            isInitialized = true;
            values.push(paramValue);
        }
        if (!values.some(p => shallow(p, paramValue))) {
            values = [paramValue];
            key = ++lastKey;
        }
        return <Comp {...props} key={key} />;
    }
}


export function withSameProp<T, U extends keyof T>(propName: U, component: (changingProp: (newValue: T[U]) => void) => (props: T) => JSX.Element) {
    let values: T[U][] = [];
    let key = ++lastKey;
    let isInitialized = false;
    const Comp = component(newValue => {
        values.push(newValue);
    });
    return (props: T): JSX.Element => {
        const propValue = props[propName];
        if (!isInitialized) {
            isInitialized = true;
            values.push();
        }
        if (!values.some(p => shallow(p, propValue))) {
            values = [propValue];
            key = ++lastKey;
        }
        return <Comp {...props} key={key} />;
    }
}

export function getLanguagesRaw(def: DefaultValuesDto) {
    return chunkString(def.language || "", 2);
}

export function getLanguages(def: DefaultValuesDto): EnumItemDto[] {
    return getLanguagesRaw(def).map(p => ({ id: p, name: p }));
}

export function leftJoin<A, B, K extends string | number, R>(a: A[], b: B[], getAKey: (item: A) => K, getBKey: (item: B) => K, getResult: (a: A, b: B | undefined) => R) {
    const bd = keyBy(b, b => getBKey(b));
    return a.map(a => getResult(a, bd[getAKey(a)]));
}

export function addOrUpdateItem<T, K>(useStore: UseListStore<T>, getId: (item: T) => K, item: T, addToEnd = true) {
    const store = useStore.getState();
    const itemId = getId(item);
    let items = store.items;
    if (items.some(p => getId(p) === itemId)) {
        items = items.map(p => getId(p) === itemId ? item : p);
    } else {
        items = addToEnd ? items.concat(item) : [item].concat(items);
    }
    store.setItems(items, false);
    store.setSelectedItem(item);
}

export function useRxJsSub(createSub: () => Subscription) {
    const [sub] = useState(() => () => {
        const sub = createSub();
        return () => sub.unsubscribe();
    });
    useSyncExternalStore(sub, () => null);
}

export function isEmailListValid(emails: string | null, limit?: number) {
    const items = (emails ?? "").split(/[,]/).map(p => p.trim()).filter(p => p.length > 0);
    if (limit != null && items.length > limit) {
        return false;
    }

    return items.every(p => validate(p));
}
