import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { Grid } from "@mui/material";
import { propBoolField, propDateField, propEnumField, propTextField } from "../../common/entityStoreForm";
import { Link, useNavigate } from "react-router-dom";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { propRequired, propValueValidator } from "../../common/entityStoreValidators";
import { useAccountStore } from "../../common/account";
import { useCountries } from "../../common/country";
import { isEmailListValid } from "../../common/utils";
import { TwProjectEditDto } from "./common";

export function TwProject({ projectId }: { projectId: string | null; }) {
    const navigate = useNavigate();
    const isGlobalAdmin = useAccountStore(p => (({ isGlobalAdmin: p.userInfo?.isGlobalAdmin ?? false })));
    const [useStore] = useState(() => createEntityStore<TwProjectEditDto>({
        path: `main/admin/twProjects`, data: {
            name: "",
            start: null,
            country: "GB",
            isTrial: false,
            testEmails: "",
        },
        id: projectId,
        getNewId: p => p.id!,
        propValidators: [
            propRequired("name"),
            propValueValidator("testEmails", p => !isEmailListValid(p, 5) && "Invalid value."),
        ],
        onCreated(entity) {
            navigate(`/projects/tw/${entity.id}`, { replace: true });
        },
    }));
    return <Grid container spacing={2} maxWidth="md">
        <Grid item xs={6}>{propTextField(useStore)("name", "Name", { multiline: true })}</Grid>
        <Grid item xs={6}>{propDateField(useStore)("start", "startFormatted", "Start", { type: "datetime" })}</Grid>
        <Grid item xs={6}>{propEnumField(useStore)("country", "Country", useCountries)}</Grid>
        <Grid item xs={12}>{propBoolField(useStore)("isTrial", "Trial mode", { disabled: !isGlobalAdmin })}
            <p>
                In trial mode, emails are sent only to <Link to={`/projects/${projectId}/users`}>project users</Link> and test email addresses.
            </p>
            <p>
                In non-trial mode, if any test email addresses are specified, emails are sent only to <Link to={`/projects/${projectId}/users`}>project users</Link> and test email addresses.
            </p>
        </Grid>
        <Grid item xs={12}>{propTextField(useStore)("testEmails", "Test email addresses (comma separated, 5 max)")}</Grid>
        <Grid item xs={12} mt={2}>
            <EntityStoreSaveButton useStore={useStore} />
        </Grid>
    </Grid>;
}
