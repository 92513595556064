import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { propCustomEnumField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { ProjectUserDto } from "./common";
import { UseListStore, createListStore } from "../../common/listStore";
import { UserDto } from "../user/common";

export function ProjectUserDialog(props: { close: () => void; projectUser?: ProjectUserDto; useProjectUsersStore: UseListStore<ProjectUserDto>; projectId: string; }) {
    const [useStore] = useState(() => createEntityStore<Partial<ProjectUserDto>>({
        data: props.projectUser ?? {
            projectId: props.projectId,
        },
        path: "main/admin/projectUsers",
        id: props.projectUser?.id ?? null,
        fetchOnInit: false,
        onSaved() {
            props.useProjectUsersStore.getState().fetch(false, true);
            props.close();
        },
    }));
    const [useUsersStore] = useState(() => createListStore<UserDto>({
        path: "main/admin/users",
        getId: p => p.id,
    }));
    const { entityId } = useStore(p => ({ entityId: p.entityId }));
    return <>
        <DialogTitle>Project user</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>{propCustomEnumField(useStore)("userId", "User", useUsersStore, {
                    getId: p => p.id, getName: p => `${p.forenames} ${p.surname} (${p.email})`,
                    disabled: entityId != null,
                })}</Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
