import { useState } from "react";
import { PageTitle } from "../../layout/PageTitle";
import { createListStore } from "../../common/listStore";
import { listHeaderItems, renderListStore } from "../../common/listStoreUi";
import { Button, Container } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useListItemDeleteApi } from "../../common/entityDelete";
import { UserDto } from "./common";

export function UsersPage() {
    const [useStore] = useState(() => createListStore<UserDto>({
        path: "main/admin/users",
        getId: p => p.id,
        listProps: [
            { column: "userName", label: "User name", width: 200 },
            { column: "forenames", label: "Forenames", width: 200 },
            { column: "surname", label: "Surname", width: 200 },
            { column: "email", label: "E-mail", width: 200 },
            { column: "isActive", label: "Active", width: 100 },
            { column: "isGlobalAdmin", label: "Global admin", width: 150 },
        ]
    }));
    const navigate = useNavigate();
    const selectedUser = useStore(p => p.selectedItem);
    const deleteUser = useListItemDeleteApi(useStore, item => `main/admin/users/${item.id}`);
    return <Container maxWidth={false}>
        {deleteUser.dialog.render()}
        <PageTitle title="Users" />
        {renderListStore(useStore, {
            header: listHeaderItems([
                ["add", () => <Button title="Add" onClick={() => navigate("/users/new")}><Add /></Button>],
                ["edit", () => <Button disabled={selectedUser == null} title="Edit" onClick={() => navigate(`/users/${selectedUser?.id}`)}><Edit /></Button>],
                ["remove", () => <Button disabled={selectedUser == null} title="Delete" onClick={() => deleteUser.deleteItem()}><Delete /></Button>],
            ]),
            onDoubleClick: p => navigate(`/users/${p.id}`),
        })}
    </Container>;
}
