import { BehaviorSubject, Observable, tap } from "rxjs";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { createAxios } from "./axios";
import { useEffect } from "react";
import { groupBy, isEqual, merge } from "lodash";
import { StoreData } from "./store";
import { KeysOfType } from "./utils";

export interface EntityStore<T> {
    serverData: T;
    isInitialized: boolean;
    isFetching: boolean;
    isFetched: boolean;
    notFound: boolean;
    failedToFetch: boolean;
    isSaving: boolean;
    isClientDataSame: boolean;
    clientData: T;
    entityId: string | number | null | undefined,
    serverData$: Observable<StoreData<T>>;
    clientData$: Observable<StoreData<T>>;
    isSaveDisabled: boolean;
    propValidationErrors: Partial<Record<keyof T, string[]>>;
    isValidationEnabled: boolean;
    propValidators: PropValidator<T>[];
    idMap: { [id: string | number]: string | number; };
    fetchServerData(): void;
    setServerData(data: T): void;
    setClientData(data: T): void;
    setClientDataProp<U extends keyof T>(prop: U, value: T[U]): void;
    save(): void;
    init(): void;
    setPropValidators(propValidators: (PropValidator<T> | null | false)[]): void;
    getSaveProps(): (keyof T)[] | undefined;
    reset(): void;
    enableValidation(): void;
}

interface EntityStoreInternal<T> extends EntityStore<T> {
    abortController: AbortController | null;
    onDataChanged(serverDataChanged: boolean): void;
}

export function getDataForSave<T extends {}>(data: T, saveProps: (keyof T)[] | undefined) {
    if (saveProps == null) {
        return data;
    }
    const dataForSave = {} as Partial<T>;
    for (const prop of saveProps) {
        dataForSave[prop] = data[prop];
    }
    return dataForSave;
}

export type PropValidator<T> = (clientData: T) => ({ prop: keyof T; errors: string[]; } | null);

export interface UseEntityStore<T> {
    (): EntityStore<T>;
    <U = Omit<EntityStore<T>, "init">>(selector?: (store: EntityStore<T>) => U, compare?: (a: U, b: U) => boolean): U;
    getState(): EntityStore<T>;
}

export function createEntityStore<T extends {}>(config: {
    path?: string;
    qs?: { [name: string]: string | number | null | undefined; },
    data: T;
    saveProps?: (keyof T)[];
    updateProps?: (keyof T)[];
    id?: string | number | null;
    getNewId?(entity: T): string | number;
    onCreated?(entity: T): void;
    onSaved?(entity: T): void;
    checkDataBeforeSave?(entity: Partial<T>, store: EntityStore<T>): Promise<boolean>;
    propValidators?: (PropValidator<T> | null | false)[];
    fetchOnInit?: false;
    isValidationEnabled?: true;
    save?(entity: T): Promise<{ data: T; idMap: { [id: string | number]: string | number } } | void>;
    applyIdMap?(entity: T, idMap: { [id: string | number]: string | number; }): T;
    allowSaveWithoutChanges?: boolean;
}): UseEntityStore<T> {
    const fetchOnInit = config.fetchOnInit !== false && config.path != null && config.id !== null;
    const serverData$ = new BehaviorSubject<StoreData<T>>({
        data: config.data,
        isFetching: fetchOnInit,
    });
    const clientData$ = new BehaviorSubject<StoreData<T>>({
        data: config.data,
        isFetching: fetchOnInit,
    });
    const useStore = create<EntityStoreInternal<T>>((set, get) => ({
        serverData: config.data,
        clientData: config.data,
        isFetching: fetchOnInit,
        isInitialized: false,
        notFound: false,
        failedToFetch: false,
        isFetched: !fetchOnInit,
        isSaving: false,
        entityId: config.id,
        serverData$: serverData$.pipe(tap({
            subscribe() {
                get().init();
            },
            unsubscribe() {
            },
        })),
        clientData$: clientData$.pipe(tap({
            subscribe() {
                get().init();
            },
            unsubscribe() {
            },
        })),
        isClientDataSame: true,
        isSaveDisabled: config.id != null || !fetchOnInit,
        propValidationErrors: {},
        isValidationEnabled: config.isValidationEnabled == null ? false : config.isValidationEnabled,
        propValidators: config.propValidators?.filter(p => p) as PropValidator<T>[] || [],
        abortController: null,
        idMap: {},
        fetchServerData: () => {
            const pathBase = config.path;
            if (pathBase == null) {
                return;
            }
            if (get().abortController) {
                get().abortController?.abort();
            }
            const abortController = new AbortController();
            set({ isFetching: true, abortController });
            get().onDataChanged(true);
            (async () => {
                try {
                    let path = pathBase;
                    const id = get().entityId;
                    if (id != null) {
                        path += "/" + id;
                    }
                    const { data } = await createAxios().get<T>(path, { signal: abortController.signal, params: config.qs });
                    set({ isFetching: false, abortController: null, isFetched: true, serverData: data, clientData: data, failedToFetch: false });
                    get().onDataChanged(true);
                } catch (err: any) {
                    set({ abortController: null, notFound: err?.response?.status === 404, failedToFetch: true });
                    get().onDataChanged(true);
                }
            })();
        },
        setServerData: (serverData) => {
            set({ serverData });
            get().onDataChanged(true);
        },
        setClientData: (clientData) => {
            set({ clientData });
            get().onDataChanged(false);
        },
        setClientDataProp<U extends keyof T>(prop: U, value: T[U]) {
            const newObj: T = { ...get().clientData };
            newObj[prop] = value;
            get().setClientData(newObj);
        },
        save: async () => {
            const pathBase = config.path;
            if (!get().isValidationEnabled) {
                set({ isValidationEnabled: true });
                get().onDataChanged(false);
            }
            if (pathBase == null && config.save == null) {
                return;
            }
            if (get().isSaveDisabled) {
                return;
            }
            if (get().abortController) {
                get().abortController?.abort();
            }
            const abortController = new AbortController();
            set({ isSaving: true, abortController, isSaveDisabled: true });
            try {
                let entityId = get().entityId;
                let clientData = get().clientData;
                if (config.applyIdMap) {
                    clientData = config.applyIdMap(clientData, get().idMap);
                }
                const clientDataForSave = getDataForSave(clientData, get().getSaveProps());
                let serverData = clientData;
                const isCreate = entityId === null;
                let isSaved = false;
                let idMap: { [id: string | number]: string | number };
                if (!config.checkDataBeforeSave || await config.checkDataBeforeSave(clientDataForSave, get())) {
                    let path = pathBase + (entityId == null ? "" : "/" + entityId);
                    if (config.save) {
                        const data = await config.save(clientData);
                        serverData = { ...serverData, ...data };
                        idMap = data?.idMap || {};
                        isSaved = true;
                    } else if (isCreate) {
                        const { data } = await createAxios().post<{ data: T; idMap: { [id: string | number]: string | number } }>(
                            path, clientDataForSave, { signal: abortController.signal, params: config.qs });
                        serverData = { ...serverData, ...data };
                        if (config.getNewId) {
                            entityId = config.getNewId(serverData);
                        }
                        idMap = data.idMap;
                    } else {
                        const { data } = await createAxios().put<{ data: T; idMap: { [id: string | number]: string | number } }>(
                            path, clientDataForSave, { signal: abortController.signal, params: config.qs });
                        serverData = { ...serverData, ...data.data };
                        idMap = data.idMap;
                    }
                    set({ idMap: merge({}, get().idMap, idMap) });
                    isSaved = true;
                } else {
                    serverData = get().serverData;
                }

                set({ entityId, isSaving: false, abortController: null, serverData, clientData: { ...serverData, ...clientData } });

                if (isCreate && isSaved && config.onCreated) {
                    config.onCreated(serverData);
                }
                if (isSaved && config.onSaved) {
                    config.onSaved(serverData);
                }

                get().onDataChanged(true);
            } catch {
                set({ isSaving: false, abortController: null });
                get().onDataChanged(true);
            }
        },
        init: () => {
            if (get().isInitialized && !get().failedToFetch) {
                return;
            }
            if (fetchOnInit) {
                get().fetchServerData();
                set({ isInitialized: true });
            } else {
                get().onDataChanged(true);
            }
            return;
        },
        onDataChanged: (serverDataChanged: boolean) => {
            const clientData = get().clientData;
            const validators = get().isValidationEnabled ? get().propValidators : [];
            const propValidationErrors = Object.fromEntries(Object.entries(
                groupBy(validators.map(p => p(clientData)).filter(p => p != null), p => p!.prop))
                .map(p => [p[0], p[1].map(p => p!.errors).flat(1)])
                .filter(p => p[1].length > 0)) as Partial<Record<keyof T, string[]>>;
            const isClientDataSame = !config.allowSaveWithoutChanges && isEqual(
                getDataForSave(get().clientData, get().getSaveProps()),
                getDataForSave(get().serverData, get().getSaveProps()));
            const isSaveDisabled = (get().entityId !== null && isClientDataSame) ||
                get().isFetching || get().isSaving || get().notFound || Object.keys(propValidationErrors).length > 0;
            set({ isSaveDisabled, propValidationErrors, isClientDataSame });

            if (serverDataChanged) {
                serverData$.next({ data: get().serverData, isFetching: get().isFetching });
            }
            clientData$.next({ data: get().clientData, isFetching: get().isFetching });
        },
        setPropValidators(propValidators) {
            set({ propValidators: propValidators.filter(p => p) as PropValidator<T>[] });
            get().onDataChanged(false);
        },
        getSaveProps() {
            return get().entityId == null ? config.saveProps : config.updateProps || config.saveProps;
        },
        reset() {
            set({
                isInitialized: false, failedToFetch: false, notFound: false,
                isFetching: fetchOnInit, isFetched: !fetchOnInit, isSaveDisabled: config.id != null || !fetchOnInit
            });
        },
        enableValidation() {
            if (!get().isValidationEnabled) {
                set({ isValidationEnabled: true });
                get().onDataChanged(false);
            }
        },
    }));
    return Object.assign(<U = Omit<EntityStore<T>, "init">>(selector?: (store: EntityStore<T>) => U, compare: (a: U, b: U) => boolean = shallow) => {
        const store = selector ?
            useStore(p => ({ init: p.init, selected: selector(p) }), (a, b) => compare(a.selected, b.selected)) :
            useStore(p => ({ init: p.init, selected: p as U }), (a, b) => compare(a.selected, b.selected));
        return initStore(store).selected;
    }, { getState: useStore.getState });
}

function initStore<T extends { init: () => void; }>(store: T) {
    const { init } = store;
    useEffect(() => {
        init();
    }, [init]);
    return store;
}

export function upsertEntityStoreListProp<TEntity extends object, TItem, TProp extends KeysOfType<TEntity, TItem[]>>(
    useStore: UseEntityStore<TEntity>, prop: TProp, value: TItem, getId: (item: TItem) => any): Promise<void> {
    const store = useStore.getState();
    const items = (store.clientData[prop] as TItem[])?.slice() || [];
    const id = getId(value);
    const index = items.findIndex(p => getId(p) === id);
    if (index >= 0) {
        items[index] = value;
    } else {
        items.push(value);
    }
    store.setClientDataProp(prop, items as TEntity[TProp]);
    return Promise.resolve();
}
