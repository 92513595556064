import { LandCode, useDefaultValues } from "./defaultValues";
import { PropValidator } from "./entityStore";
import { formatDate, numberFormatByLandCode } from "./localization";
import { KeysOfTypeGet } from "./utils";
import { validate as validateEmail } from "email-validator";

type Errors = string[] | string | undefined | null | false;

export function propValueValidator<T, U extends keyof T>(prop: U, getErrors: (value: T[U], entity: T) => Errors): PropValidator<T> {
    return (entity: T) => {
        const errors = getErrors(entity[prop], entity);
        return {
            prop: prop,
            errors: typeof errors === "string" ? [errors] : Array.isArray(errors) ? errors : [],
        };
    };
}

export function propLengthMin<T>(prop: KeysOfTypeGet<T, string | null | undefined>, minLength: number): PropValidator<T> {
    return propEmptyOr(prop, propValueValidator(prop, p => ((p || "") as string).length < minLength && `Minimum length is ${minLength}.`));
}

export function propLengthMax<T>(prop: KeysOfTypeGet<T, string | null | undefined>, maxLength: number): PropValidator<T> {
    return propEmptyOr(prop, propValueValidator(prop, p => ((p || "") as string).length > maxLength && `Maximum length is ${maxLength}.`));
}

export function propLength<T>(prop: KeysOfTypeGet<T, string | null | undefined>, minLength: number, maxLength: number): PropValidator<T> {
    return combine([propLengthMin(prop, minLength), propLengthMax(prop, maxLength)]);
}

export function propInvalidCharacter<T, U extends KeysOfTypeGet<T, string | undefined>>(prop: U, character: string): PropValidator<T> {
    return propEmptyOr(prop, propValueValidator(prop, p => ((p || "") as string).indexOf(character) >= 0 && `Field contains invalid character '${character}'.`));
}

export function propRegex<T>(prop: KeysOfTypeGet<T, string | null | undefined>, regex: RegExp, errors: Errors): PropValidator<T> {
    return propEmptyOr(prop, propValueValidator(prop, p => regex.test((p || "") as string) && errors));
}

export function propNotRegex<T, U extends KeysOfTypeGet<T, string | null | undefined>>(prop: U, regex: RegExp, errors: Errors): PropValidator<T> {
    return propEmptyOr(prop, propValueValidator(prop, p => !regex.test((p || "") as string) && errors));
}

export function propOnlyDigits<T>(prop: KeysOfTypeGet<T, string | null | undefined>): PropValidator<T> {
    return propNotRegex(prop, /^[0-9]*$/, "Field can contain only digits.");
}

export function propEmptyOr<T, U extends KeysOfTypeGet<T, any>>(prop: U, or: PropValidator<T>): PropValidator<T> {
    return entity => entity[prop] == null || entity[prop] === "" || entity[prop] === 0 ? { prop, errors: [] } : or(entity);
}

export function propRequired<T, U extends KeysOfTypeGet<T, any>>(prop: U, customMessage?: string, isValid?: (value: T[U]) => boolean): PropValidator<T> {
    return propValueValidator(prop, p => (p == null || p === "") && (isValid == null || !isValid(p)) && (customMessage || `Required field.`));
}

export function propValidValue<T, U extends KeysOfTypeGet<T, string | number | null>>(prop: U, propFormatted: KeysOfTypeGet<T, string | undefined>): PropValidator<T> {
    return propValueValidator(prop, (p, e) => p == null && ((e[propFormatted] as string || "") !== "") && `Invalid value.`);
}

export function propInt<T, U extends KeysOfTypeGet<T, number | null | undefined>>(prop: U): PropValidator<T> {
    return propValueValidator(prop, p => p != null && (p as number) % 1 > 0 && `Value must be an integer.`);
}

export function propCurrency<T, U extends KeysOfTypeGet<T, number | null>>(prop: U, customMessage?: string): PropValidator<T> {
    const format = numberFormatByLandCode[useDefaultValues.getState().clientData.landCode];
    return propValueValidator(prop, p => p != null && ((p as number) * Math.pow(10, format.currencyDecimalPlaces)) % 1 !== 0 && (customMessage || `Invalid currency value.`));
}

export function propMaxDecimalPlaces<T, U extends KeysOfTypeGet<T, number>>(prop: U, maxDecimalPlaces: number): PropValidator<T> {
    return propValueValidator(prop, p => p != null && ((p as number) * Math.pow(10, maxDecimalPlaces)) % 1 !== 0 && `Maximum decimal places is ${maxDecimalPlaces}`);
}

export function propNotZero<T, U extends KeysOfTypeGet<T, number | null | undefined>>(prop: U, customMessage?: string): PropValidator<T> {
    return propValueValidator(prop, p => p === 0 && (customMessage || `Value must not be zero.`));
}

export function propNumber<T, U extends KeysOfTypeGet<T, number | null | undefined>>(prop: U, checkNumber: (value: number, entity: T) => Errors): PropValidator<T> {
    return propValueValidator(prop, (p, e) => typeof p === "number" && checkNumber(p, e));
}

export function propMediaCode<T>(prop: KeysOfTypeGet<T, string | null | undefined>): PropValidator<T>[] {
    return [propOnlyDigits(prop), propLength(prop, 4, 4)];
}

export function propMinValue<T, U extends KeysOfTypeGet<T, number | null | undefined>>(prop: U, min: number): PropValidator<T> {
    return propNumber(prop, p => p < min && `Value must be ${min} or more.`);
}

export function propMaxValue<T, U extends KeysOfTypeGet<T, number | null | undefined>>(prop: U, max: number): PropValidator<T> {
    return propNumber(prop, p => p > max && `Value must be ${max} or less.`);
}

export function propMinValueExclusive<T, U extends KeysOfTypeGet<T, number | null | undefined>>(prop: U, min: number): PropValidator<T> {
    return propNumber(prop, p => p <= min && `Value must be more than ${min}.`);
}

export function propMaxValueExclusive<T, U extends KeysOfTypeGet<T, number | null | undefined>>(prop: U, max: number): PropValidator<T> {
    return propNumber(prop, p => p >= max && `Value must be less than ${max}.`);
}

export function propMinDateExclusive<T, U extends KeysOfTypeGet<T, string | null | undefined>>(prop: U, min: string, landCode: LandCode): PropValidator<T> {
    return propValueValidator(prop, p => p != null && p <= min && `Value must be more than ${formatDate(min, landCode)}.`);
}

export function propMaxDateExclusive<T, U extends KeysOfTypeGet<T, string | null | undefined>>(prop: U, max: string, landCode: LandCode): PropValidator<T> {
    return propValueValidator(prop, p => p != null && p >= max && `Value must be less than ${formatDate(max, landCode)}.`);
}

export function propIf<T>(shouldValidate: (entity: T) => boolean, getValidator: () => PropValidator<T>): PropValidator<T> {
    return (entity: T) => shouldValidate(entity) ? getValidator()(entity) : null;
}

export function propValidEmail<T, U extends KeysOfTypeGet<T, string | null | undefined>>(prop: U): PropValidator<T> {
    return propValueValidator(prop, p => p != null && p !== "" && !validateEmail(p as string) && "Invalid e-mail address.");
}

function combine<T>(validators: PropValidator<T>[]): PropValidator<T> {
    return p => {
        for (const validator of validators) {
            const result = validator(p);
            if (result != null && result.errors.length > 0) {
                return result;
            }
        }

        return null;
    }
}

