import { useState } from "react";
import { PageTitle } from "../../layout/PageTitle";
import { createEntityStore } from "../../common/entityStore";
import { Container, Grid } from "@mui/material";
import { propBoolField, propTextField } from "../../common/entityStoreForm";
import { useNavigate, useParams } from "react-router-dom";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { propRequired, propValidEmail } from "../../common/entityStoreValidators";
import { UserSaveDto } from "./common";

export function UserPage() {
    const params = useParams<{ id: string; }>();
    const navigate = useNavigate();
    const [useStore] = useState(() => createEntityStore<Partial<UserSaveDto>>({
        path: "main/admin/users", data: {
            isActive: true,
            userName: "",
            forenames: "",
            surname: "",
            email: "",
        },
        id: params.id === "new" ? null : params.id,
        getNewId: p => p.id!,
        propValidators: [
            propRequired("userName"),
            propRequired("email"),
            propValidEmail("email"),
        ],
        onCreated(entity) {
            navigate(`/users/${entity.id}`, { replace: true });
        },
    }));
    const store = useStore(p => ({
        userName: p.serverData.userName,
        entityId: p.entityId,
        isFetched: p.isFetched,
    }));
    return <Container maxWidth={false}>
        <PageTitle title={store.entityId == null && store.isFetched ? "New user" : `User ${store.userName ?? ""}`} />
        <Grid container spacing={2} maxWidth="md">
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={4}>{propTextField(useStore)("userName", "User name")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("forenames", "Forenames")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("surname", "Surname")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("email", "E-mail")}</Grid>
                <Grid item xs={4}>{propBoolField(useStore)("isActive", "Active")}</Grid>
                <Grid item xs={4}>{propBoolField(useStore)("isGlobalAdmin", "Global admin")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("password", "New password")}</Grid>
            </Grid>
            <Grid item xs={12}>
                <EntityStoreSaveButton useStore={useStore} />
            </Grid>
        </Grid>
    </Container>;
}
