import { Grid, Radio } from "@mui/material";
import { formatDate, formatDateTime, formatNumber } from "./localization";
import { useDefaultValues } from "./defaultValues";

const borderColor = "#bbb";
const selectedRowColor = "#eee";

export function DataGrid<T>({ columns, rows, getId, selectedRow, onRowClick, onRowDoubleClick }: {
    columns: Column<T>[];
    rows: T[];
    getId(row: T): string | number;
    selectedRow?: T | null;
    onRowClick?(row: T): void;
    onRowDoubleClick?(row: T): void;
}) {
    const landCode = useDefaultValues(p => p.clientData.landCode);
    function cell(col: Column<T>, isHeader: boolean, value: any, id: string | number | undefined) {

        function renderValue(col: Column<T>, value: string | T[keyof T]): import("react").ReactNode {
            if (isHeader) {
                return col.label;
            }
            if (col.getValue) {
                value = col.getValue(value);
            }
            if (col.type === "radio") {
                return <Radio sx={{ p: 0 }} checked={value === col.checkedValue} onChange={!col.setValue ? undefined : (p => p.target.checked && col.setValue!(id!, col.checkedValue))} />;
            }
            if (typeof value === "string" && col.type === "date") {
                return formatDate(value, landCode);
            }
            if (typeof value === "string" && col.type === "datetime") {
                return formatDateTime(value, landCode);
            }
            if (typeof value === "string") {
                return value;
            }
            if (typeof value === "boolean") {
                return value ? "Yes" : "No";
            }
            if (typeof value === "number") {
                return formatNumber(value, false, landCode);
            }
        }

        return <Grid item key={col.prop + col.label} width={col.width} fontWeight={isHeader ? "bold" : undefined}
            flex={col.width == null ? col.flex == null ? 1 : col.flex : undefined} p={1} borderRight={1}
            textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" height={40} borderColor={borderColor}
            textAlign={isHeader ? "left" : col.type === "radio" ? "center" : col.type === "number" || col.type === "currency" ? "right" : undefined}
        >{renderValue(col, value)}</Grid>;
    }

    return <Grid container direction="column" width={columns.some(p => p.flex) ? undefined : "fit-content"}>
        <Grid item container borderTop={1} borderBottom={1} borderLeft={1} direction="row" wrap="nowrap" borderColor={borderColor}>
            {columns.map(col => cell(col, true, col.label, undefined))}
        </Grid>
        {rows.map(row => <Grid item container key={getId(row)} borderBottom={1} borderLeft={1} direction="row" wrap="nowrap" borderColor={borderColor}
            bgcolor={row === selectedRow ? selectedRowColor : undefined}
            onClick={onRowClick == null ? undefined : () => onRowClick(row)}
            onDoubleClick={onRowDoubleClick == null ? undefined : () => onRowDoubleClick(row)}
        >
            {columns.map(col => cell(col, false, row[col.prop], getId(row)))}
        </Grid>)}
    </Grid>;
}

export interface Column<T> {
    label: string;
    prop: keyof T & string;
    width?: number;
    flex?: number;
    type?: "currency" | "date" | "datetime" | "bool" | "number" | "radio";
    checkedValue?: string | number | boolean | null;
    setValue?(rowId: string | number, value: string | number | boolean | null | undefined): void;
    getValue?(value: any): any;
}