import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { propCustomEnumField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { ProspectDto, ProspectGroupProspectDto } from "./common";
import { UseListStore, createListStore } from "../../common/listStore";
import { useStateWithDeps } from "use-state-with-deps";

export function ProspectGroupProspectDialog(props: { close: () => void; prospectGroupId: string; prospectGroupProspect?: ProspectGroupProspectDto; projectId: string; useProspectGroupProspectsStore: UseListStore<ProspectGroupProspectDto>; }) {
    const [useStore] = useState(() => createEntityStore<Partial<ProspectGroupProspectDto>>({
        data: props.prospectGroupProspect ?? {
            projectId: props.projectId,
            prospectGroupId: props.prospectGroupId,
        },
        path: "main/admin/prospectGroupProspects",
        id: props.prospectGroupProspect?.id ?? null,
        fetchOnInit: false,
        onSaved() {
            props.useProspectGroupProspectsStore.getState().fetch(false, true);
            props.close();
        },
        propValidators: [
        ],
    }));
    const [useProspectsStore] = useStateWithDeps(() => createListStore<ProspectDto>({
        path: props.projectId == null ? undefined : "main/admin/prospects?projectId=" + props.projectId,
        getId: p => p.id,
    }), [props.projectId]);
    return <>
        <DialogTitle>Prospect</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>{propCustomEnumField(useStore)("prospectId", "Prospect", useProspectsStore, {
                    getId: p => p.id, getName: p => p.forenames + " " + p.surname + " (" + p.email + ")",
                })}</Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
