import { useEffect, useMemo, useState } from "react";
import { Box, Grid, List, ListSubheader } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Email, Folder, People, Tune } from "@mui/icons-material";
import { ProjectDto } from "../project/common";
import { useDocumentTitle } from "../../layout/page";
import { Resources } from "../project/Resources";
import { SectionData, Sections, getInitialExpandedSections } from "../../common/PageMenu";
import { createAxios } from "../../common/axios";
import { Emails } from "../project/Emails";
import { EmailSendQueues } from "../project/EmailSendQueues";
import { ProspectGroups } from "../project/ProspectGroups";
import { Prospects } from "../project/Prospects";
import { EmailTemplates } from "../project/EmailTemplates";
import { TwProjectUsers } from "./TwProjectUsers";
import { TwProject } from "./TwProject";

export function TwProjectPage() {
    const params = useParams<{ id: string; section?: string; }>();
    const projectId = params.id === "new" ? null : params.id!;
    const navigate = useNavigate();

    const [projectName, setProjectName] = useState<string>();
    const pageTitle = projectId ? `Project ${projectName ?? ""}` : "New project";
    useDocumentTitle(pageTitle);
    useEffect(() => {
        if (projectId != null) {
            createAxios().get<ProjectDto>(`main/admin/twProjects/${projectId}`).then(p => setProjectName(p.data.name));
        }
    }, [projectId]);

    const selectedSection = params.section ?? "main";
    function setSelectedSection(p: string) {
        navigate(`/projects/tw/${params.id}${p === "main" ? "" : "/" + p}`);
    }
    const sections = useMemo(() => {
        return getSections(projectId);
    }, [projectId]);
    const [expandedSections, setExpandedSections] = useState<string[]>(
        () => getInitialExpandedSections(sections, selectedSection));

    return <>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Box sx={{ width: 250, flexShrink: 0, bgcolor: "#f4f4f4", flexDirection: "column" }}>
                <List subheader={
                    <ListSubheader disableSticky style={{ lineHeight: "25px", marginTop: "8px", marginBottom: "5px" }}>
                        {pageTitle}
                    </ListSubheader>
                }>
                    <Sections sections={sections} selectedSection={selectedSection} setSelectedSection={setSelectedSection}
                        expandedSections={expandedSections} setExpandedSections={setExpandedSections} />
                </List>
            </Box>
            <Box sx={{ flexGrow: 1, mt: 4, ml: 2, mb: 2, mr: 2 }}>
                <Grid container>
                    {selectedSection === "main" && <Grid item xs={12}>
                        <TwProject projectId={projectId} />
                    </Grid>}
                    {selectedSection === "users" && <Grid item xs={12}>
                        <TwProjectUsers projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "emailTemplates" && <Grid item xs={12}>
                        <EmailTemplates projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "resources" && <Grid item xs={12}>
                        <Resources projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "prospects" && <Grid item xs={12}>
                        <Prospects projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "prospectGroups" && <Grid item xs={12}>
                        <ProspectGroups projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "emailSendQueues" && <Grid item xs={12}>
                        <EmailSendQueues projectId={projectId!} />
                    </Grid>}
                    {selectedSection === "scheduledEmails" && <Grid item xs={12}>
                        <Emails projectId={projectId!} scheduled />
                    </Grid>}
                    {selectedSection === "sentEmails" && <Grid item xs={12}>
                        <Emails projectId={projectId!} sent />
                    </Grid>}
                </Grid>
            </Box>
        </Box>
    </>;
}

function getSections(projectId: string | null): SectionData[] {
    const isNew = projectId == null;
    return [
        { key: "main", label: "Project", icon: <Tune /> },
        !isNew && { key: "users", label: "Users", icon: <People /> },
        !isNew && { key: "prospects", label: "Prospects", icon: <People /> },
        !isNew && { key: "prospectGroups", label: "Prospect groups", icon: <People /> },
        !isNew && {
            key: "emails", label: "Emails", icon: <Email />, subsections: [
                { key: "sentEmails", label: "Sent" },
                { key: "scheduledEmails", label: "Scheduled" },
                { key: "emailTemplates", label: "Templates" },
                { key: "emailSendQueues", label: "Send queues" },
            ]
        },
        !isNew && { key: "resources", label: "Resources", icon: <Folder /> },
    ].filter(p => p) as SectionData[];
}
