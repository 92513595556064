import { MenuProps } from "@mui/material";
import { useState } from "react";

export function DropdownMenu<T>(props: {
    triggerElement(open: (event: React.MouseEvent<HTMLElement>) => void): JSX.Element;
    itemTemplate(close: () => void): T;
    menu(menuProps: MenuProps, itemTemplate: T, close: () => void): JSX.Element;
}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onClose = () => {
        setAnchorEl(null);
    };
    return <>
        {props.triggerElement(handleClick)}
        {props.menu({ anchorEl, open, onClose }, props.itemTemplate(onClose), onClose)}
    </>;
}
