import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { propLengthMin, propRequired, propValueValidator } from "../../common/entityStoreValidators";
import { minPasswordLength } from "../../common/account";
import { renderClose } from "../../common/dialog";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { propTextField } from "../../common/entityStoreForm";

export function ChangePasswordDialog(props: {
    close: () => void;
}) {
    const [useStore] = useState(() => createEntityStore<ChangePasswordDto>({
        data: {},
        path: "main/admin/account/changePassword",
        id: null,
        onSaved() {
            props.close();
        },
        propValidators: [
            propRequired("currentPassword"),
            propRequired("newPassword"),
            propRequired("confirmPassword"),
            propLengthMin("newPassword", minPasswordLength),
            propValueValidator("confirmPassword", (p, e) => p !== e.newPassword && "Passwords do not match."),
        ]
    }));
    return <>
        <DialogTitle>Change password</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>{propTextField(useStore)("currentPassword", "Current password", { type: "password" })}</Grid>
                <Grid item xs={12}>{propTextField(useStore)("newPassword", "New password", { type: "password" })}</Grid>
                <Grid item xs={12}>{propTextField(useStore)("confirmPassword", "Confirm new password", { type: "password" })}</Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}

interface ChangePasswordDto {
    currentPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
}
