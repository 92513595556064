import { Button } from "@mui/material";
import { UseEntityStore } from "./entityStore";
import { KeysOfType } from "./utils";

export function SaveButton(props: { save(): void; disabled: boolean; }) {
    return <>
        <Button onClick={props.save} disabled={props.disabled}>Save</Button>
    </>
}

export function EntityStoreSaveButton<T>(props: { useStore: UseEntityStore<T>; }) {
    const { isSaveDisabled, save } = props.useStore(p => ({ isSaveDisabled: p.isSaveDisabled, save: p.save }));
    return <>
        <Button onClick={() => {
            save();
        }} disabled={isSaveDisabled}>Save</Button>
    </>;
}

export function EntitySubStoreSaveButton<T, U>(props: {
    useStore: UseEntityStore<T>;
    useMainStore: UseEntityStore<U>,
    prop: KeysOfType<U, T[]>;
    getId(item: T, index: number): any;
    close?(): void;
}) {
    const { isSaveDisabled, enableValidation, id } = props.useStore(p => ({ isSaveDisabled: p.isSaveDisabled, enableValidation: p.enableValidation, id: p.entityId }));
    function save() {
        enableValidation();
        if (props.useStore.getState().isSaveDisabled) {
            return;
        }
        let items = props.useMainStore.getState().clientData[props.prop] as T[];
        const item = props.useStore.getState().clientData;
        if (id != null) {
            items = items.map((p, i) => props.getId(p, i) === id ? item : p);
        } else {
            items = items.concat(item);
        }
        props.useMainStore.getState().setClientDataProp(props.prop, items as U[KeysOfType<U, T[]>]);
        if (props.close) {
            props.close();
        }
    }
    return <>
        <Button onClick={save} disabled={isSaveDisabled}>Save</Button>
    </>
}
