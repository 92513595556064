import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { MutableRefObject } from "react";
import { UseEntityStore } from "./entityStore";
import { EnumItemDto } from "./enum";
import { DateField, NumberField } from "./form";
import { UseListStore } from "./listStore";
import { ifNull, KeysOfType } from "./utils";
import { Property } from "csstype";
import { arrayBufferToBase64 } from "./download";

export function entityHeader(items: (null | false | [string, () => JSX.Element])[], itemsRight: (null | false | [string, () => JSX.Element])[] = []) {
    const definedItems = (items as [string, () => JSX.Element][]).filter(p => p);
    const definedItemsRight = (itemsRight as [string, () => JSX.Element][]).filter(p => p);
    return definedItems.length === 0 && definedItemsRight.length === 0 ? undefined : <Grid item container flexWrap="nowrap">
        <Grid item container mb={2} spacing={2} sx={{ flexGrow: 1 }}>{definedItems.map(p => <Grid item key={p[0]}>{p[1]()}</Grid>)}</Grid>
        <Grid item xs container mb={2} spacing={2} flexWrap="nowrap">{definedItemsRight.map(p => <Grid item key={p[0]}>{p[1]()}</Grid>)}</Grid>
    </Grid>;
}

export function PropFileField<T>(props: {
    useStore: UseEntityStore<T>;
    prop: KeysOfType<T, File | null | undefined>;
    label: string;
    disabled?: boolean;
    bgColor?: string;
    onChange?(newValue: File | null | undefined, oldValue: File | null | undefined, entity: T): void;
}) {
    const { propValue, firstError, setClientDataProp } = props.useStore(p => ({
        propValue: p.clientData[props.prop] as File | null | undefined,
        firstError: (p.propValidationErrors[props.prop] || [])[0],
        setClientDataProp: p.setClientDataProp
    }));
    return <div style={{ position: "relative" }}>
        <input type="file" style={{ position: "absolute", width: "100%", height: "100%", opacity: 0, zIndex: 1 }}
            disabled={props.disabled}
            onChange={async p => {
                const files = (p.target as HTMLInputElement).files;
                const file = files && files[0] as File;
                if (file != null) {
                    const data = arrayBufferToBase64(await file.arrayBuffer());
                    (file as any).toJSON = () => ({ binaryData: data, mimeType: file.type });
                    (file as any).toString = () => data;
                }
                setClientDataProp(props.prop, file as T[KeysOfType<T, File | null | undefined>]);
                props.onChange?.(file, propValue, props.useStore.getState().clientData);
            }} />
        <TextField fullWidth label={props.label} disabled={props.disabled} value={propValue != null ? propValue.name : ""} multiline
            style={{ backgroundColor: props.bgColor }}
            InputProps={{ readOnly: true }}
            error={firstError != null} helperText={firstError} />
    </div>;
}

export function propFileField<T>(useStore: UseEntityStore<T>, defaultProps?: {
    disabled?: boolean;
}) {
    return (prop: KeysOfType<T, File | null | undefined>, label: string, props?: {
        disabled?: boolean;
        bgColor?: string;
        onChange?(newValue: File | null | undefined, oldValue: File | null | undefined, entity: T): void;
    }) =>
        <PropFileField useStore={useStore} prop={prop} label={label} {...defaultProps} {...props} />;
}

export function PropTextField<T>(props: {
    useStore: UseEntityStore<T>;
    prop: KeysOfType<T, string | null | undefined>;
    label: string;
    disabled?: boolean;
    readOnly?: boolean;
    multiline?: boolean;
    type?: "file" | "password";
    bgColor?: string;
}) {
    const { propValue, firstError, setClientDataProp } = props.useStore(p => ({
        propValue: p.clientData[props.prop],
        firstError: (p.propValidationErrors[props.prop] || [])[0],
        setClientDataProp: p.setClientDataProp
    }));
    return <TextField value={props.type === "file" ? undefined : ifNull(propValue as string, "")} label={props.label} disabled={props.disabled}
        InputProps={{ readOnly: props.readOnly }} autoComplete={props.type === "password" ? "new-password" : undefined}
        style={{ backgroundColor: props.bgColor }} fullWidth
        error={firstError != null} helperText={firstError} multiline={props.multiline} type={props.type} onChange={p => {
            if (props.type === "file") {
                var input = p.currentTarget as HTMLInputElement;
                var file = input.files && input.files[0];
                if (file == null) {
                    setClientDataProp(props.prop, undefined as T[KeysOfType<T, string | null | undefined>]);
                    return;
                }
                var reader = new FileReader();
                reader.onload = e => {
                    const value = e.target?.result == null ? undefined : arrayBufferToBase64(e.target?.result as ArrayBuffer);
                    setClientDataProp(props.prop, value as T[KeysOfType<T, string | null | undefined>]);
                };
                reader.onerror = function (e) {
                    setClientDataProp(props.prop, undefined as T[KeysOfType<T, string | null | undefined>]);
                };
                reader.readAsArrayBuffer(file);
            }
            setClientDataProp(props.prop, p.target.value as T[KeysOfType<T, string | null | undefined>]);
        }} />;
}

export function propTextField<T>(useStore: UseEntityStore<T>, defaultProps?: {
    disabled?: boolean;
    readOnly?: boolean;
    multiline?: boolean;
}) {
    return (prop: KeysOfType<T, string | null | undefined>, label: string, props?: {
        disabled?: boolean;
        readOnly?: boolean;
        multiline?: boolean;
        type?: "file" | "password";
        bgColor?: string;
    }) =>
        <PropTextField useStore={useStore} prop={prop} label={label} {...defaultProps} {...props} />;
}

export const boolInputMarginTop = 0.5;

export function PropBoolField<T>(props: {
    useStore: UseEntityStore<T>;
    prop: KeysOfType<T, boolean | null | undefined>;
    label: string;
    disabled?: boolean;
    readOnly?: boolean;
}) {
    const { propValue, firstError, setClientDataProp } = props.useStore(p => ({
        propValue: p.clientData[props.prop],
        firstError: (p.propValidationErrors[props.prop] || [])[0],
        setClientDataProp: p.setClientDataProp
    }));
    return <FormControl error={firstError != null} fullWidth>
        <FormControlLabel
            control={<Checkbox disabled={props.disabled} readOnly={props.readOnly}
                checked={ifNull(propValue as boolean | null | undefined, false)}
                indeterminate={propValue == null}
                onChange={p => { setClientDataProp(props.prop, p.target.checked as T[KeysOfType<T, boolean | null | undefined>]); }}
            />}
            label={props.label}
        />
        {firstError != null && <FormHelperText>{firstError}</FormHelperText>}
    </FormControl>;
}

export function propBoolField<T>(useStore: UseEntityStore<T>, defaultProps?: {
    disabled?: boolean;
    readOnly?: boolean;
}) {
    return (prop: KeysOfType<T, boolean | null | undefined>, label: string, props?: {
        disabled?: boolean;
        readOnly?: boolean;
    }) =>
        <PropBoolField useStore={useStore} prop={prop} label={label} {...defaultProps} {...props} />;
}

export function PropCustomEnumField<T, U extends KeysOfType<T, string | number | null | undefined>, V>(props: {
    useStore: UseEntityStore<T>;
    prop: U;
    label: string;
    useEnumStore: UseListStore<V>;
    disabled?: boolean;
    readOnly?: boolean;
    onChange?(newValue: T[U], oldValue: T[U], entity: T): void;
    addNone?: boolean;
    noneValue?: T[U];
    getId(item: V): string | number;
    getName(item: V): string;
    getActive?(item: V): boolean;
    bgColor?: string;
}) {
    const { propValue, firstError, setClientDataProp } = props.useStore(p => ({
        propValue: p.clientData[props.prop],
        firstError: (p.propValidationErrors[props.prop] || [])[0],
        setClientDataProp: p.setClientDataProp
    }));
    const enumStore = props.useEnumStore();
    const inputValue = propValue != null && enumStore.items.some(p => props.getId(p) === propValue) ? propValue : "";
    return <FormControl error={firstError != null} fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <Select value={inputValue} label={props.label} disabled={props.disabled} readOnly={props.readOnly}
            style={{ backgroundColor: props.bgColor }}
            onChange={p => {
                const newValue = (p.target.value === "" ? props.noneValue ?? null : p.target.value) as T[U];
                setClientDataProp(props.prop, newValue);
                if (props.onChange != null) {
                    props.onChange(newValue, propValue, props.useStore.getState().clientData);
                }
            }}>
            {props.addNone && <MenuItem value="">(none)</MenuItem>}
            {(props.getActive == null ? enumStore.items : enumStore.items.filter(p => props.getId(p) === inputValue || props.getActive!(p)))
                .map(p => <MenuItem value={props.getId(p)} key={props.getId(p)}>{props.getName(p)}</MenuItem>)}
        </Select>
        {firstError != null && <FormHelperText>{firstError}</FormHelperText>}
    </FormControl>;
}

export function propCustomEnumField<T>(useStore: UseEntityStore<T>, defaultProps?: {
    disabled?: boolean;
    readOnly?: boolean;
}) {
    return function <U extends KeysOfType<T, string | number | null | undefined>, V>(prop: U, label: string, useEnumStore: UseListStore<V>, props: {
        disabled?: boolean;
        readOnly?: boolean;
        onChange?(newValue: T[U], oldValue: T[U], entity: T): void;
        addNone?: boolean;
        noneValue?: T[U];
        getId(item: V): string | number;
        getName(item: V): string;
        getActive?(item: V): boolean;
        bgColor?: string;
    }) {
        return <PropCustomEnumField useStore={useStore} prop={prop} label={label} useEnumStore={useEnumStore} {...defaultProps} {...props} />;
    };
}

export function propEnumField<T>(useStore: UseEntityStore<T>, defaultProps?: {
    disabled?: boolean;
    readOnly?: boolean;
}) {
    return function <U extends KeysOfType<T, string | number | null | undefined>, V extends EnumItemDto>(prop: U, label: string, useEnumStore: UseListStore<V>, props?: {
        disabled?: boolean;
        readOnly?: boolean;
        onChange?(newValue: T[U], oldValue: T[U], entity: T): void;
        addNone?: boolean;
        bgColor?: string;
    }) {
        return propCustomEnumField(useStore, defaultProps)(prop, label, useEnumStore, { ...props, getId: p => p.id, getName: p => p.name, getActive: p => !p.inactive });
    };
}

export function PropSimpleEnumField<T, U extends KeysOfType<T, string | number | boolean | null | undefined>>(props: {
    useStore: UseEntityStore<T>;
    prop: U;
    label: string;
    enumItems: EnumItemDto[];
    disabled?: boolean;
    readOnly?: boolean;
    onChange?(newValue: T[U], oldValue: T[U], entity: T): void;
    bgColor?: string;
}) {
    const { propValue, firstError, setClientDataProp } = props.useStore(p => ({
        propValue: p.clientData[props.prop],
        firstError: (p.propValidationErrors[props.prop] || [])[0],
        setClientDataProp: p.setClientDataProp
    }));
    const inputValue = propValue != null && props.enumItems.some(p => p.id === propValue) ? propValue : "";
    return <FormControl error={firstError != null} fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <Select value={inputValue} label={props.label} disabled={props.disabled} readOnly={props.readOnly}
            style={{ backgroundColor: props.bgColor }}
            onChange={p => {
                setClientDataProp(props.prop, p.target.value as T[U]);
                if (props.onChange != null) {
                    props.onChange(p.target.value as T[U], propValue, props.useStore.getState().clientData);
                }
            }}>
            {props.enumItems.map(p => <MenuItem value={p.id} key={p.id}>{p.name}</MenuItem>)}
        </Select>
        {firstError != null && <FormHelperText>{firstError}</FormHelperText>}
    </FormControl>;
}

export function propSimpleEnumField<T>(useStore: UseEntityStore<T>, defaultProps?: {
    disabled?: boolean;
    readOnly?: boolean;
}) {
    return <U extends KeysOfType<T, string | number | boolean | null | undefined>>(prop: U, label: string, enumItems: EnumItemDto[], props?: {
        disabled?: boolean;
        readOnly?: boolean;
        onChange?(newValue: T[U], oldValue: T[U], entity: T): void;
        bgColor?: string;
    }) =>
        <PropSimpleEnumField useStore={useStore} prop={prop} label={label} enumItems={enumItems} {...defaultProps} {...props} />;
}

export function PropNumberField<T>(props: {
    useStore: UseEntityStore<T>;
    prop: KeysOfType<T, number | null | undefined>;
    propFormatted: KeysOfType<T, string | undefined> | null;
    label: string;
    disabled?: boolean;
    readOnly?: boolean;
    isCurrency?: boolean;
    currency?: string;
    unit?: string;
    textAlign?: Property.TextAlign;
    transformValue?(value: number | null | undefined): number | null | undefined;
    onChange?(newValue: number | null | undefined, oldValue: number | null | undefined, entity: T): void;
    inputRef?: MutableRefObject<HTMLInputElement | undefined>;
}) {
    const { propValue, propValueFormatted, setClientDataProp, firstError } = props.useStore(p => ({
        propValue: p.clientData[props.prop] as number | null,
        propValueFormatted: props.propFormatted == null ? null : p.clientData[props.propFormatted],
        setClientDataProp: p.setClientDataProp,
        firstError: (p.propValidationErrors[props.prop] || [])[0],
    }));
    return <NumberField {...props} error={firstError} value={propValue} valueFormatted={propValueFormatted as string | null} setValue={(value, valueFormatted) => {
        setClientDataProp(props.prop, value as T[KeysOfType<T, number | null | undefined>]);
        if (props.propFormatted) {
            setClientDataProp(props.propFormatted, valueFormatted as T[KeysOfType<T, string | undefined>]);
        }
        if (props.onChange != null) {
            props.onChange(value, propValue, props.useStore.getState().clientData);
        }
    }} />;
}

export function propNumberField<T>(useStore: UseEntityStore<T>, defaultProps?: {
    disabled?: boolean;
    readOnly?: boolean;
    isCurrency?: boolean;
    currency?: string;
    unit?: string;
    textAlign?: Property.TextAlign;
    transformValue?(value: number | null | undefined): number | null | undefined;
    onChange?(newValue: number | null | undefined, oldValue: number | null | undefined, entity: T): void;
    inputRef?: MutableRefObject<HTMLInputElement | undefined>;
}) {
    return (prop: KeysOfType<T, number | null | undefined>, propFormatted: KeysOfType<T, string | undefined> | null, label: string, props?: {
        disabled?: boolean;
        readOnly?: boolean;
        isCurrency?: boolean;
        currency?: string;
        unit?: string;
        textAlign?: Property.TextAlign;
        transformValue?(value: number | null | undefined): number | null | undefined;
        onChange?(newValue: number | null | undefined, oldValue: number | null | undefined, entity: T): void;
        inputRef?: MutableRefObject<HTMLInputElement | undefined>;
    }) =>
        <PropNumberField useStore={useStore} prop={prop} propFormatted={propFormatted} label={label} {...defaultProps} {...props} />;
}

export function PropDateField<T>(props: {
    useStore: UseEntityStore<T>;
    prop: KeysOfType<T, string | null | undefined>;
    propFormatted: KeysOfType<T, string | undefined> | null;
    label: string;
    disabled?: boolean;
    readOnly?: boolean;
    type?: "datetime";
}) {
    const { propValue, propValueFormatted, setClientDataProp, firstError } = props.useStore(p => ({
        propValue: p.clientData[props.prop], propValueFormatted: props.propFormatted == null ? null : p.clientData[props.propFormatted],
        setClientDataProp: p.setClientDataProp, firstError: (p.propValidationErrors[props.prop] || [])[0],
    }));
    return <DateField {...props} error={firstError} value={propValue as string | null} valueFormatted={propValueFormatted as string | null} setValue={(value, valueFormatted) => {
        setClientDataProp(props.prop, value as T[KeysOfType<T, string | null | undefined>]);
        if (props.propFormatted) {
            setClientDataProp(props.propFormatted, valueFormatted as T[KeysOfType<T, string | undefined>]);
        }
    }} />;
}

export function propDateField<T>(useStore: UseEntityStore<T>, defaultProps?: {
    disabled?: boolean;
    readOnly?: boolean;
}) {
    return (prop: KeysOfType<T, string | null | undefined>, propFormatted: KeysOfType<T, string | undefined> | null, label: string, props?: {
        disabled?: boolean;
        readOnly?: boolean;
        type?: "datetime";
    }) =>
        <PropDateField useStore={useStore} prop={prop} propFormatted={propFormatted} label={label} {...defaultProps} {...props} />;
}
