import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { Grid, Typography } from "@mui/material";
import { propEnumField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { createListStore } from "../../common/listStore";
import { renderListStore } from "../../common/listStoreUi";
import { DonationListItemDto, ProjectDonationSettingsDto } from "../project/common";
import { useStateWithDeps } from "use-state-with-deps";
import { EnumItemDto } from "../../common/enum";

export function Donations({ projectId }: { projectId: string; }) {
    const [useSettingsStore] = useState(() => createEntityStore<ProjectDonationSettingsDto>({
        path: `main/admin/gdProjects/${projectId}/donationSettings`, data: {
            donationEmailTemplateId: null,
        },
        propValidators: [],
    }));
    const [useTemplatesStore] = useStateWithDeps(() => createListStore<EnumItemDto>({
        path: projectId == null ? undefined : "main/admin/emailTemplates?projectId=" + projectId,
        getId: p => p.id,
    }), [projectId]);
    const [useListStore] = useState(() => createListStore<DonationListItemDto>({
        path: "main/admin/donations?projectId=" + projectId,
        getId: p => p.id,
        listProps: [
            { column: "createdAt", label: "Date", width: 220, type: "datetime" },
            { column: "stripeId", label: "Stripe ID", width: 320 },
            { column: "amount", label: "Amount", width: 100 },
            { column: "currency", label: "Currency", width: 90 },
            { column: "forenames", label: "Forenames", width: 200 },
            { column: "surname", label: "Surname", width: 200 },
            { column: "email", label: "E-mail", width: 250 },
        ],
    }));

    return <Grid container spacing={2}>
        <Grid container item maxWidth="md">
            <Grid item xs={6}>
                {propEnumField(useSettingsStore)("donationEmailTemplateId", "Donation email template", useTemplatesStore, {
                    addNone: true,
                })}
            </Grid>
            <Grid item xs={12} mt={2}>
                <EntityStoreSaveButton useStore={useSettingsStore} />
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6" component="div">Donations</Typography>
            {renderListStore(useListStore)}
        </Grid>
    </Grid>;
}
