import axios, { AxiosResponse } from "axios";
import { map, Observable } from "rxjs";
import { create } from "zustand";
import { isResponseUnauthorized, useAccountStore } from "./account";
import { apiUrl } from "./api";

let lastErrorId = 0;

export interface ErrorData {
    id: number;
    status?: number;
    message?: string;
}

export interface CommStore {
    requestCount: number;
    isCommunicating: boolean;
    isCommunicatingForLongTime: boolean;
    timer: number | null;
    errors: ErrorData[];
    requestStarted(): void;
    requestFinished(error: any): void;
    clearError(error: ErrorData): void;
}

export const useCommStore = create<CommStore>((set, get) => ({
    isCommunicating: false,
    isCommunicatingForLongTime: false,
    requestCount: 0,
    timer: null,
    errors: [],
    requestStarted() {
        const timer = get().isCommunicating ? get().timer : window.setTimeout(() => {
            set({ timer: null, isCommunicatingForLongTime: true });
        }, 100);
        set(store => ({ requestCount: store.requestCount + 1, isCommunicating: true, timer }));
    },
    requestFinished(error: any) {
        const requestCount = get().requestCount - 1;
        const isCommunicating = requestCount > 0;
        const isCommunicatingForLongTime = isCommunicating && get().isCommunicatingForLongTime;
        let timer = get().timer;
        if (!isCommunicating && timer != null) {
            window.clearTimeout(timer);
            timer = null;
        }

        if (error != null && error.code !== "ERR_CANCELED" && error.response?.status !== 401) {
            set({ errors: get().errors.concat({ id: ++lastErrorId, status: error.response?.status, message: error.response?.data }) });
        }

        set({ requestCount, isCommunicating, isCommunicatingForLongTime, timer });
    },
    clearError(error) {
        set({ errors: get().errors.filter(p => p !== error) });
    },
}));

export function createAxios() {
    const accessToken = useAccountStore.getState().getAndRefreshAccessToken();
    const commStore = useCommStore.getState();
    const inst = axios.create({
        baseURL: apiUrl,
        headers: accessToken ? {
            Authorization: `Bearer ${accessToken}`,
        } : {}
    });
    inst.interceptors.request.use(ok => {
        commStore.requestStarted();
        return ok;
    }, err => {
        return Promise.reject(err);
    });
    inst.interceptors.response.use(ok => {
        commStore.requestFinished(undefined);
        return ok;
    }, err => {
        commStore.requestFinished(err);
        if (isResponseUnauthorized(err)) {
            useAccountStore.getState().setAccessToken(null);
        }
        return Promise.reject(err);
    });
    return inst;
}

export function axiosObservable<T>(createResponse: (abortSignal: AbortSignal) => Promise<AxiosResponse<T>>) {
    return axiosObservableRaw(createResponse).pipe(map(p => p.data));
}

export function axiosObservableRaw<T>(createResponse: (abortSignal: AbortSignal) => Promise<AxiosResponse<T>>) {
    return new Observable<AxiosResponse<T>>(subscriber => {
        const abort = new AbortController();
        createResponse(abort.signal)
            .then(response => {
                subscriber.next(response);
                subscriber.complete();
            }, err => {
                subscriber.error(err);
            });
        return () => {
            abort.abort();
        };
    });
}
