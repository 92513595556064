import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { DashboardPage } from "./feature/DashboardPage";
import { LoginPage } from "./feature/account/LoginPage";
import { AnonymousLayout } from "./layout/AnonymousLayout";
import { AuthLayout } from "./layout/AuthLayout";
import { PageTitle } from "./layout/PageTitle";
import { ProjectsPage } from "./feature/project/ProjectsPage";
import { GdProjectPage } from "./feature/gdProject/GdProjectPage";
import { UsersPage } from "./feature/user/UsersPage";
import { UserPage } from "./feature/user/UserPage";
import { useCommStore } from "./common/axios";
import { TwProjectPage } from "./feature/twProject/TwProjectPage";

const statusDict: { [num: number]: string; } = {
    400: "Invalid data",
    403: "Forbidden",
    404: "Not found",
    409: "Conflict",
    500: "Server error",
}

function CommStore() {
    const { isCommunicatingForLongTime, errors } = useCommStore(p => ({ isCommunicatingForLongTime: p.isCommunicatingForLongTime, errors: p.errors }));
    return <>
        {isCommunicatingForLongTime && <LinearProgress style={{ position: "absolute", width: "100%", zIndex: 10000 }} />}
        {errors.map(p => <Dialog key={p.id} open onClose={() => useCommStore.getState().clearError(p)}>
            <DialogTitle style={{ margin: 10 }}>Error ({p.status == null ? "unknown" : statusDict[p.status] == null ? p.status : statusDict[p.status]})</DialogTitle>
            <DialogContent style={{ margin: 10, minWidth: 300, whiteSpace: "pre-line" }}>
                {typeof p.message === "string" ? p.message : ""}
            </DialogContent>
            <DialogActions style={{ margin: 10 }}>
                <Button onClick={() => useCommStore.getState().clearError(p)}>Ok</Button>
            </DialogActions>
        </Dialog>)}
    </>;
}

export function App() {
    return <>
        <CssBaseline />
        <CommStore />
        <Routes>
            <Route element={<AnonymousLayout />}>
                <Route path="/login" element={<LoginPage />} />
            </Route>
            <Route element={<AuthLayout />}>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/projects/gd/:id" element={<GdProjectPage />} />
                <Route path="/projects/gd/:id/:section" element={<GdProjectPage />} />
                <Route path="/projects/tw/:id" element={<TwProjectPage />} />
                <Route path="/projects/tw/:id/:section" element={<TwProjectPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/users/:id" element={<UserPage />} />
                <Route path="*" element={<PageTitle title="Not found" />} />
            </Route>
        </Routes>
    </>;
}
