import dayjs from "dayjs";
import { LandCode, useDefaultValues } from "./defaultValues";

interface InputNumberFormat {
    thousandSeparator: string;
    decimalSeparator: string;
    currencyDecimalPlaces: number;
}

export const numberFormatByLandCode: { [landCode in LandCode]: InputNumberFormat; } = {
    "GB": { decimalSeparator: ".", thousandSeparator: ",", currencyDecimalPlaces: 2 },
}

export const numberInputRegexByLandCode: { [landCode in LandCode]: RegExp; } = {
    "GB": /^-?[\d,]+(?:\.[\d ]*)?$/,
}

export const timeFormatByLandCode: { [landCode in LandCode]: string; } = {
    "GB": "hh:mm:ss A",
}

export const dateFormatByLandCode: { [landCode in LandCode]: string; } = {
    "GB": "M/D/YYYY",
}

export const monthFormatByLandCode: { [landCode in LandCode]: string; } = {
    "GB": "M/YYYY",
}

export const dateTimeFormatByLandCode: { [landCode in LandCode]: string; } = {
    "GB": dateFormatByLandCode["GB"] + " " + timeFormatByLandCode["GB"],
}

export const dateInputRegexByLandCode: { [landCode in LandCode]: RegExp; } = {
    "GB": /^\s*\d{1,2}\s*\/\s*\d{1,2}\s*\/\s*\d{4}\s*$/,
}

export const dateTimeInputRegexByLandCode: { [landCode in LandCode]: RegExp; } = {
    "GB": /^\s*\d{1,2}\s*\/\s*\d{1,2}\s*\/\s*\d{4}\s+\d{1,2}:\s*\d{1,2}:\s*\d{1,2}\s*(AM|PM)\s*$/,
}

export const monthInputRegexByLandCode: { [landCode in LandCode]: RegExp; } = {
    "GB": /^\d{1,2}\/\d{4}$/,
}

export function formatDate(value: string | null | undefined, landCode: LandCode) {
    if (value == null) {
        return "";
    }
    const format = dateFormatByLandCode[landCode];
    return dayjs(value).format(format);
}

export function formatDateTime(value: string | null | undefined, landCode: LandCode = "GB") {
    if (value == null) {
        return "";
    }
    const format = dateFormatByLandCode[landCode] + " " + timeFormatByLandCode[landCode];
    return dayjs.utc(value).local().format(format);
}

export function formatNumber(value: number | null | undefined, isCurrency: boolean, landCode?: LandCode) {
    if (value == null) {
        return "";
    }
    const format = numberFormatByLandCode[landCode || useDefaultValues.getState().clientData.landCode];
    const parts = value.toString(10).split(".");
    const left = parts[0];
    let right = parts[1];
    const leftFormatted = chunk(left, 3, true).join(format.thousandSeparator);
    if (isCurrency && format.currencyDecimalPlaces > 0 && (right == null || right.length < format.currencyDecimalPlaces)) {
        right = (right == null ? "" : right).padEnd(format.currencyDecimalPlaces, "0");
    }
    const rightFormatted = right == null ? "" : right;
    return leftFormatted + (rightFormatted === "" ? "" : format.decimalSeparator + rightFormatted);
}

export function parseNumber(text: string, landCode: LandCode): number | null {
    const regex = numberInputRegexByLandCode[landCode];
    if (text.trim() === "" || !regex.test(text)) {
        return null;
    }
    const format = numberFormatByLandCode[landCode];
    const parts = text.split(format.decimalSeparator);
    const left = parts[0]!;
    const right = parts[1];
    const leftFormatted = left.match(/[-\d]/g)!.join("");
    const rightFormatted = right == null || right.trim() === "" ? "" : right.match(/\d/g)!.join("");
    return Number.parseFloat(leftFormatted + "." + rightFormatted);
}

function chunk(str: string, size: number, fromRight: boolean) {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);
    let i = 0, o = 0;
    if (fromRight) {
        const remainder = str.length % size;
        if (remainder > 0) {
            chunks[0] = str.substring(0, remainder);
            i = 1;
            o = remainder;
        }
    }
    for (; i < numChunks; ++i, o += size) {
        chunks[i] = str.substring(o, o + size);
    }
    return chunks;
}
