import { KeyboardEvent, useState } from 'react';
import { LockOutlined } from "@mui/icons-material";
import { Alert, AlertColor, Avatar, Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import { useAccountStore } from '../../common/account';
import { createAxios } from '../../common/axios';
import { useDocumentTitle } from '../../layout/page';
import { theme } from '../../theme';

export function LoginPage() {
    const auth = useAccountStore();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [userMfaSessionId, setUserMfaSessionId] = useState(localStorage.getItem("userMfaSessionId"));
    const [isUserMfaSessionCodeRequired, setIsUserMfaSessionCodeRequired] = useState(false);
    const [userMfaSessionCode, setUserMfaSessionCode] = useState("");
    const [signingIn, setSingingIn] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [message, setMessage] = useState<{ text: string; severity: AlertColor; }>();
    const signIn = async () => {
        setSingingIn(true);
        setNetworkError(false);
        setMessage(undefined);
        try {
            const { data } = await createAxios().post<AuthResponse>("main/common/account/login", { username, password, userMfaSessionId, userMfaSessionCode });
            if (data.userMfaSessionId != null) {
                setUserMfaSessionId(data.userMfaSessionId);
            }
            setMessage(data.errorMessage != null ? { text: data?.errorMessage, severity: "error" } :
                data.isUserMfaSessionCodeRequired ? {
                    text: "Verification code is required for sign in. New code was sent to your e-mail.",
                    severity: "warning"
                } : undefined);
            if (data.isUserMfaSessionCodeRequired) {
                setIsUserMfaSessionCodeRequired(true);
                setUserMfaSessionCode("");
            } else if (data.accessToken != null) {
                auth.setAccessToken(data.accessToken);
                localStorage.setItem("userMfaSessionId", userMfaSessionId!);
            }
        } catch {
            setMessage(undefined);
            setNetworkError(true);
        } finally {
            setSingingIn(false);
        }
    };
    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            signIn();
        }
    };
    useDocumentTitle("Login");
    return <Container maxWidth="xs" sx={{ p: 7 }}>
        <Stack direction="column" alignItems="center" gap={1}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}><LockOutlined /></Avatar>
            <Typography component="h1" variant="h5">Sign in</Typography>
            <TextField label="Username" value={username} onChange={p => setUsername(p.target.value)} onKeyDown={onKeyDown} />
            <TextField label="Password" value={password} onChange={p => setPassword(p.target.value)} onKeyDown={onKeyDown} type="password" />
            {isUserMfaSessionCodeRequired && <TextField label="Verification code from e-mail" value={userMfaSessionCode} onChange={p => setUserMfaSessionCode(p.target.value)} onKeyDown={onKeyDown} />}
            <Button disabled={signingIn || (isUserMfaSessionCodeRequired && userMfaSessionCode.length === 0)} onClick={signIn}>Sign in</Button>
            {message && <Box mb={3}>
                <Alert severity={message.severity}>
                    {message.severity === "error" && <strong>Failed to sign in!</strong>} {message.text}
                </Alert>
            </Box>}
            {networkError && <Box mb={3}>
                <Alert severity="error">
                    <strong>Failed to sign in!</strong> Network error occurred.
                </Alert>
            </Box>}
        </Stack>
    </Container>;
}

export interface AuthResponse {
    accessToken: string | null;
    userMfaSessionId: string | null;
    isUserMfaSessionCodeRequired: boolean;
    errorMessage: string;
}
