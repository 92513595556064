import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { renderClose, useDialog } from "../../common/dialog";
import { propTextField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { ProspectGroupDto, ProspectGroupProspectDto } from "./common";
import { UseListStore, createListStore } from "../../common/listStore";
import { listHeaderItems, renderListStore } from "../../common/listStoreUi";
import { useListItemDeleteApi } from "../../common/entityDelete";
import { ProspectGroupProspectDialog } from "./ProspectGroupProspectDialog";
import { useStateWithDeps } from "use-state-with-deps";
import { Add, Delete, Edit } from "@mui/icons-material";

export function ProspectGroupDialog(props: { close: () => void; prospectgroup?: ProspectGroupDto; projectId: string; useProspectGroupsStore: UseListStore<ProspectGroupDto>; }) {
    const [useStore] = useState(() => createEntityStore<Partial<ProspectGroupDto>>({
        data: props.prospectgroup ?? {
            projectId: props.projectId,
            name: "",
        },
        path: "main/admin/prospectGroups",
        id: props.prospectgroup?.id ?? null,
        fetchOnInit: false,
        onSaved() {
            props.useProspectGroupsStore.getState().fetch(false, true);
            props.close();
        },
        propValidators: [
        ],
    }));
    const { prospectGroupId } = useStore(p => ({ prospectGroupId: p.entityId as string | null }));
    const [useProspectsStore] = useStateWithDeps(() => createListStore<ProspectGroupProspectDto>({
        path: prospectGroupId == null ? undefined : `main/admin/prospectGroupProspects?prospectGroupId=${props.prospectgroup?.id ?? ""}&projectId=${props.projectId}`,
        getId: p => p.id,
        listProps: [
            { column: "prospectForenames", label: "Forenames", width: 250 },
            { column: "prospectSurname", label: "Surname", width: 250 },
            { column: "prospectEmail", label: "Email", width: 250 },
            { column: "createdAt", label: "Created at", width: 250, type: "datetime" },
        ],
    }), [prospectGroupId]);
    const prospectsStore = useProspectsStore(p => ({ selectedItem: p.selectedItem }));
    const addProspectDialog = useDialog(close => <ProspectGroupProspectDialog close={close} projectId={props.projectId!} prospectGroupId={prospectGroupId!} useProspectGroupProspectsStore={useProspectsStore} />);
    const editProspectDialog = useDialog(close => <ProspectGroupProspectDialog close={close} prospectGroupProspect={prospectsStore.selectedItem!} projectId={props.projectId!} prospectGroupId={prospectGroupId!} useProspectGroupProspectsStore={useProspectsStore} />);
    const deleteProspect = useListItemDeleteApi(useProspectsStore, item => `main/admin/prospectGroupProspects/${item.id}`);
    return <>
        {addProspectDialog.render()}
        {editProspectDialog.render()}
        {deleteProspect.dialog.render()}
        <DialogTitle>Prospect group</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={4}>{propTextField(useStore)("name", "Name")}</Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="div">Prospects</Typography>
                    {renderListStore(useProspectsStore, {
                        header: listHeaderItems([
                            ["add", () => <Button title="Add" onClick={() => addProspectDialog.show()} disabled={prospectGroupId == null}><Add /></Button>],
                            ["edit", () => <Button disabled={prospectsStore.selectedItem == null} title="Edit" onClick={() => editProspectDialog.show()}><Edit /></Button>],
                            ["remove", () => <Button disabled={prospectsStore.selectedItem == null} title="Delete" onClick={() => deleteProspect.deleteItem()}><Delete /></Button>],
                        ]),
                        onDoubleClick: () => editProspectDialog.show(),
                    })}</Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
