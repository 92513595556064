export const appUrl = process.env.NODE_ENV !== "development" ?
    `https://jarise.co.uk/` :
    `https://localhost:7185/`;

export const serverIp = "192.46.236.76";

export const apiUrl = process.env.NODE_ENV !== "development" ?
    `https://jarise.co.uk/api/` :
    `https://localhost:7185/api/`;

export const getProjectAppUrl = (projectId: string) => process.env.NODE_ENV !== "development" ?
    `https://gd.jarise.co.uk/${projectId}` :
    `http://localhost:5002/${projectId}`;
