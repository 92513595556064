import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { renderClose } from "../../common/dialog";
import { propTextField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { ProspectDto } from "./common";
import { UseListStore } from "../../common/listStore";
import { propRequired, propValidEmail } from "../../common/entityStoreValidators";

export function ProspectDialog(props: { close: () => void; prospect?: ProspectDto; projectId: string; useProspectsStore: UseListStore<ProspectDto>; }) {
    const [useStore] = useState(() => createEntityStore<Partial<ProspectDto>>({
        data: props.prospect ?? {
            projectId: props.projectId,
            title: "",
            forenames: "",
            surname: "",
            email: "",
            preferredName: "",
            prospectId: "",
        },
        path: "main/admin/prospects",
        id: props.prospect?.id ?? null,
        fetchOnInit: false,
        onSaved() {
            props.useProspectsStore.getState().fetch(false, true);
            props.close();
        },
        propValidators: [
            propValidEmail("email"),
            propRequired("email"),
            propRequired("prospectId"),
        ],
    }));
    return <>
        <DialogTitle>Prospect</DialogTitle>
        {renderClose(props.close)}
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={4}>{propTextField(useStore)("title", "Title")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("forenames", "Forenames")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("surname", "Surname")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("email", "Email")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("preferredName", "Preferred name")}</Grid>
                <Grid item xs={4}>{propTextField(useStore)("prospectId", "Prospect ID")}</Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <EntityStoreSaveButton useStore={useStore} />
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </>;
}
