import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useDialog } from "../../common/dialog";
import { createListStore } from "../../common/listStore";
import { listHeaderItems, renderListStore } from "../../common/listStoreUi";
import { useListItemDeleteApi } from "../../common/entityDelete";
import { ChallengeDto } from "../project/common";
import { useStateWithDeps } from "use-state-with-deps";
import { ChallengeDialog } from "./ChallengeDialog";

export function Challenges({ projectId }: { projectId: string; }) {
    const [useStore] = useStateWithDeps(() => createListStore<ChallengeDto>({
        path: projectId == null ? undefined : "main/admin/challenges?projectId=" + projectId,
        getId: p => p.id,
        listProps: [
            { column: "name", label: "Name", width: 400 },
            { column: "createdAt", label: "Created at", width: 300, type: "datetime" },
            { column: "order", label: "Order", width: 100 },
        ],
    }), [projectId]);
    const store = useStore(p => ({ selectedItem: p.selectedItem }));
    const addDialog = useDialog(close => <ChallengeDialog close={close} useChallengesStore={useStore} projectId={projectId!} />);
    const editDialog = useDialog(close => <ChallengeDialog close={close} challenge={store.selectedItem!} useChallengesStore={useStore} projectId={projectId!} />);
    const deleteItem = useListItemDeleteApi(useStore, item => `main/admin/challenges/${item.id}`);

    return <>
        {addDialog.render()}
        {editDialog.render()}
        {deleteItem.dialog.render()}
        {renderListStore(useStore, {
            header: listHeaderItems([
                ["add", () => <Button title="Add" onClick={() => addDialog.show()} disabled={projectId == null}><Add /></Button>],
                ["edit", () => <Button disabled={store.selectedItem == null} title="Edit" onClick={() => editDialog.show()}><Edit /></Button>],
                ["remove", () => <Button disabled={store.selectedItem == null} title="Delete" onClick={() => deleteItem.deleteItem()}><Delete /></Button>],
            ]),
            onDoubleClick: () => editDialog.show(),
        })}
    </>;
}
