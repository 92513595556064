import { useState } from "react";
import { createEntityStore } from "../../common/entityStore";
import { Grid } from "@mui/material";
import { propTextField } from "../../common/entityStoreForm";
import { EntityStoreSaveButton } from "../../common/SaveButton";
import { ProjectMainPageDto } from "../project/common";

export function MainPage({ projectId }: { projectId: string; }) {
    const [useStore] = useState(() => createEntityStore<Partial<ProjectMainPageDto>>({
        path: `main/admin/gdProjects/${projectId}/mainPage`, data: {
            header: "",
            funds: "",
            footer: "",
        },
        propValidators: [],
    }));
    return <Grid container spacing={2} maxWidth="md">
        <Grid item xs={12}>{propTextField(useStore)("header", "Main page header", { multiline: true })}</Grid>
        <Grid item xs={12}>{propTextField(useStore)("funds", "Main page funds", { multiline: true })}</Grid>
        <Grid item xs={12}>{propTextField(useStore)("footer", "Main page footer", { multiline: true })}</Grid>
        <Grid item xs={12} mt={2}>
            <EntityStoreSaveButton useStore={useStore} />
        </Grid>
    </Grid>;
}
