import { InputAdornment, TextField } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { MutableRefObject } from "react";
import { useDefaultValues } from "./defaultValues";
import { dateFormatByLandCode, dateInputRegexByLandCode, dateTimeFormatByLandCode, dateTimeInputRegexByLandCode, formatNumber, monthFormatByLandCode, monthInputRegexByLandCode, parseNumber } from "./localization";
import { ifNull, isoDateFormat, isoMonthFormat, isoUtcDateTimeFormat } from "./utils";
import { Property } from "csstype";
import { BaseDateTimePickerProps } from "@mui/x-date-pickers/DateTimePicker/shared";

export const BgColorNegative = "#ffc7b8";

export function NumberField(props: {
    value: number | null;
    valueFormatted?: string | null;
    setValue?(value: number | null, valueFormatted: string): void;
    label: string;
    error?: string | false;
    disabled?: boolean;
    readOnly?: boolean;
    isCurrency?: boolean;
    currency?: string;
    unit?: string;
    textAlign?: Property.TextAlign;
    transformValue?(value: number | null | undefined): number | null | undefined;
    inputRef?: MutableRefObject<HTMLInputElement | undefined>;
}) {
    const { landCode, currency } = useDefaultValues(p => ({ landCode: p.clientData.landCode, currency: p.clientData.currency }));
    const inputValue = props.valueFormatted != null ? props.valueFormatted : props.value == null ? "" :
        formatNumber(props.transformValue == null ? props.value : props.transformValue(props.value), props.isCurrency || false, landCode);
    const endAdornment = !props.isCurrency && props.unit == null ? undefined :
        <InputAdornment position="end">{ifNull(props.unit, ifNull(props.currency, currency))}</InputAdornment>;
    return <TextField value={inputValue} label={props.label} fullWidth disabled={props.disabled} InputProps={{ readOnly: props.readOnly, endAdornment }}
        inputProps={{ style: { textAlign: props.textAlign || "right" } }} error={!!props.error} helperText={props.error} onChange={p => {
            const value = parseNumber(p.target.value, landCode);
            if (props.setValue) {
                props.setValue(value, p.target.value);
            }
        }} inputRef={props.inputRef && (p => props.inputRef!.current = p)} />;
}

export function DateField(props: {
    value: string | null;
    valueFormatted?: string | null;
    setValue?(value: string | null, valueFormatted: string): void;
    label: string;
    error?: string | false;
    disabled?: boolean;
    readOnly?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    type?: "datetime" | "month";
    inputRef?: React.Ref<HTMLInputElement>;
}) {
    const { landCode } = useDefaultValues(p => ({ landCode: p.clientData.landCode }));
    const regex = props.type === "datetime" ? dateTimeInputRegexByLandCode[landCode] :
        props.type === "month" ? monthInputRegexByLandCode[landCode] :
            dateInputRegexByLandCode[landCode];
    const displayFormat = props.type === "datetime" ? dateTimeFormatByLandCode[landCode] :
        props.type === "month" ? monthFormatByLandCode[landCode] :
            dateFormatByLandCode[landCode];
    const pickerProps: Partial<BaseDateTimePickerProps<dayjs.Dayjs | null, dayjs.Dayjs | null>> = {};
    if (props.type === "month") {
        pickerProps.openTo = "month";
    }
    function getDayjsValue(value: string | null) {
        return props.type === "datetime" ? dayjs.utc(value) : dayjs(value);
    }
    function getFormattedValue(value: dayjs.Dayjs | null) {
        return value == null || !value.isValid() ? "" :
            props.type === "datetime" ? value.local().format(displayFormat) :
                value.local().format(displayFormat);
    }
    function getDataValue(value: dayjs.Dayjs | null) {
        return value == null || !value.isValid() ? null :
            props.type === "datetime" ? value.utc().format(isoUtcDateTimeFormat) :
                props.type === "month" ? value.format(isoMonthFormat) :
                    value.local().format(isoDateFormat);
    }
    const inputValue = props.valueFormatted ?? getFormattedValue(getDayjsValue(props.value));
    return <DesktopDateTimePicker
        {...pickerProps}
        views={props.type === "month" ? ["year", "month"] : props.type === "datetime" ? ["year", "month", "day", "hours", "minutes", "seconds"] : ["year", "month", "day"]}
        value={getDayjsValue(props.value).local()}
        onChange={value => {
            if (props.setValue != null) {
                props.setValue(getDataValue(value), getFormattedValue(value));
            }
        }}
        inputRef={props.inputRef}
        readOnly={props.readOnly}
        disabled={props.disabled}
        renderInput={inputProps => {
            return <TextField fullWidth value={inputValue} label={props.label} disabled={props.disabled} InputProps={{ ...inputProps.InputProps, readOnly: props.readOnly }}
                inputRef={inputProps.inputRef} error={props.error != null} helperText={props.error} onChange={p => {
                    if (props.setValue != null) {
                        const value = p.target.value.trim() === "" || !regex.test(p.target.value) ? null : dayjs(p.target.value, displayFormat);
                        props.setValue(getDataValue(value), p.target.value);
                    }
                }} onKeyDown={props.onKeyDown} />
        }}
    />;
}
