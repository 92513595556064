import { Button, Grid } from "@mui/material";
import { serverIp } from "../../common/api";

export function Proxy({ projectId }: { projectId: string | null; }) {
    const sampleCode = `    server {
        listen       80;
        server_name  givingday.example.com;

        location / {
            proxy_pass https://gd.jarise.co.uk;
            proxy_set_header project-id "${projectId}";
        }
    }`;
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            You can either use your own reverse proxy server, or contact us if you want us to manage one for you.
            IP address of our server is {serverIp}.
        </Grid>
        <Grid item xs={12}>
            Example of simple unsecured NGINX configuration:
        </Grid>
        <Grid item xs={12}>
            <code style={{ whiteSpace: "pre-wrap", display: "block", backgroundColor: "#eaeaea" }}>{sampleCode}</code>
        </Grid>
        <Grid item xs={12}>
            <Button onClick={async () => {
                await navigator.clipboard.writeText(sampleCode);
            }}>Copy to clipboard</Button>
        </Grid>
    </Grid>;
}
