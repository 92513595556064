import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useDialog } from "../../common/dialog";
import { createListStore } from "../../common/listStore";
import { listHeaderItems, renderListStore } from "../../common/listStoreUi";
import { useListItemDeleteApi } from "../../common/entityDelete";
import { TwProjectUserDto } from "./common";
import { TwProjectUserDialog } from "./TwProjectUserDialog";
import { useAccountStore } from "../../common/account";
import { useStateWithDeps } from "use-state-with-deps";
import { useTwProjectUserRolesStore } from "../../common/enum";

export function TwProjectUsers({ projectId }: { projectId: string; }) {
    const { isGlobalAdmin } = useAccountStore(p => (({ isGlobalAdmin: p.userInfo?.isGlobalAdmin ?? false })));
    const [useStore] = useStateWithDeps(() => createListStore<TwProjectUserDto>({
        path: projectId == null ? undefined : "main/admin/twProjectUsers?projectId=" + projectId,
        getId: p => p.id,
        listProps: [
            { column: "forenames", label: "Forenames", width: 200 },
            { column: "surname", label: "Surname", width: 200 },
            { column: "email", label: "E-mail", width: 200 },
            { column: "role", label: "Role", width: 200, enumStore: useTwProjectUserRolesStore },
        ],
    }), [projectId]);
    const store = useStore(p => ({ selectedItem: p.selectedItem }));
    const addDialog = useDialog(close => <TwProjectUserDialog close={close} useProjectUsersStore={useStore} projectId={projectId} />, "xl");
    const editDialog = useDialog(close => <TwProjectUserDialog close={close} projectUser={store.selectedItem!} useProjectUsersStore={useStore} projectId={projectId} />, "xl");
    const deleteItem = useListItemDeleteApi(useStore, item => `main/admin/twProjectUsers/${item.id}`);

    return <>
        {addDialog.render()}
        {editDialog.render()}
        {deleteItem.dialog.render()}
        {renderListStore(useStore, {
            header: listHeaderItems([
                isGlobalAdmin && ["add", () => <Button title="Add" onClick={() => addDialog.show()} disabled={projectId == null}><Add /></Button>],
                isGlobalAdmin && ["edit", () => <Button disabled={store.selectedItem == null} title="Edit" onClick={() => editDialog.show()}><Edit /></Button>],
                isGlobalAdmin && ["remove", () => <Button disabled={store.selectedItem == null} title="Delete" onClick={() => deleteItem.deleteItem()}><Delete /></Button>],
            ]),
            onDoubleClick: isGlobalAdmin ? () => editDialog.show() : undefined,
        })}
    </>;
}
